<script>
	import { dbPageKey } from "../services/database";
	import { playSound } from "../services/utils.ts";
	let changePage = async () => {
		dbPageKey().set("LOBBY_SCREEN");
	};
	let playClick = () => playSound("CLICK");
</script>

<div class="loadingScreen">
	<img class="logo" src="/assets/svg/logo.svg" alt="logo svg" />
</div>
<button class="btnStart" on:mousedown={playClick} on:click={changePage}><span class="btnText">Enter Game</span></button>

<style>
	.loadingScreen {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: center;
		align-items: center;
		justify-content: center;
	}
	.logo {
		width: 100%;
		height: 100%;
		max-width: 330px;
		max-height: 275px;
	}
	.btnStart {
		width: 148px;
		height: 56px;
		left: 0;
		right: 0;
		top: 80%;
		margin-left: auto;
		margin-right: auto;
		background: #ffffff;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: fixed;
	}
	.btnText {
		font-family: manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 24px;
		color: #000000;
	}
	@media only screen and (max-width: 780px) {
		.logo {
			max-width: 220px;
			max-height: 150px;
		}
		.btnStart {
			width: 100px;
			height: 40px;
		}
		.btnText {
			font-size: 14px;
			line-height: 18px;
		}
	}
</style>
