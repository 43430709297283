<script>
	import { onDestroy, onMount } from "svelte";
	export let expiryAt;

	let expiryTimer, time;

	onMount(() => {
		time = Math.round((expiryAt - new Date().getTime()) / 1000);
		expiryTimer = setInterval(() => {
			time = Math.round((expiryAt - new Date().getTime()) / 1000);
		}, 1000);
	});
	onDestroy(() => {
		clearInterval(expiryTimer);
	});
</script>

{#if time > 0 && time <= 30}
	<div class="circle">
		<div class="text">{time}</div>
	</div>
{/if}

<style>
	.circle {
		margin-left: 19px;
		width: 34px; /* Width + border should be equal to countbox size in RoundInfo */
		height: 34px;
		border: 2px solid #ffffff;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.text {
		font-family: Manrope;
		font-style: normal;
		font-weight: 800;
		font-size: 18px;
		line-height: 18px;
		color: #ffffff;
	}

	@media screen and (max-width: 780px) {
		.circle {
			margin-left: 14px;
			width: 30px;
			height: 30px;
		}
		.text {
			font-size: 14px;
			line-height: 14px;
		}
	}
</style>
