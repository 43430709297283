import { notification } from "./store.js";

export function send(message, type = "default") {
	notification.set({ message, type });
}

// export function danger(msg, timeout) {
// 	send(msg, "danger", timeout);
// }

// export function warning(msg, timeout) {
// 	send(msg, "warning", timeout);
// }

export function hostLeft(msg) {
	send(msg, "disconnected");
}

export function iAmHost(msg) {
	send(msg, "hostLeft");
}

export function userLeft(msg) {
	send(msg, "disconnected");
}

export function reconnected(msg) {
	send(msg, "reconnected");
}

// export function success(msg, timeout) {
// 	send(msg, "success", timeout);
// }
