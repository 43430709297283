<script>
</script>

<svg width="100%" height="100%" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
	<rect width="34" height="34" rx="17" fill="white" fill-opacity="0.16" />
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M18 9.70306C18 7.84606 15.69 6.99206 14.481 8.40206L10.84 12.6501C10.7461 12.7598 10.6296 12.8479 10.4984 12.9084C10.3672 12.9688 10.2244 13.0001 10.08 13.0001H10C9.20435 13.0001 8.44129 13.3161 7.87868 13.8787C7.31607 14.4413 7 15.2044 7 16.0001V18.0001C7 18.7957 7.31607 19.5588 7.87868 20.1214C8.44129 20.684 9.20435 21.0001 10 21.0001H10.08C10.2244 21 10.3672 21.0313 10.4984 21.0917C10.6296 21.1522 10.7461 21.2403 10.84 21.3501L11.552 20.7381L10.839 21.3491L14.481 25.5991C15.69 27.0091 18 26.1541 18 24.2971V9.70306ZM21.293 14.2931C21.4805 14.1056 21.7348 14.0003 22 14.0003C22.2652 14.0003 22.5195 14.1056 22.707 14.2931L24 15.5861L25.293 14.2931C25.3852 14.1975 25.4956 14.1214 25.6176 14.069C25.7396 14.0165 25.8708 13.989 26.0036 13.9878C26.1364 13.9867 26.2681 14.012 26.391 14.0622C26.5138 14.1125 26.6255 14.1868 26.7194 14.2807C26.8133 14.3746 26.8875 14.4862 26.9378 14.6091C26.9881 14.732 27.0134 14.8637 27.0123 14.9965C27.0111 15.1292 26.9835 15.2605 26.9311 15.3825C26.8787 15.5045 26.8025 15.6148 26.707 15.7071L25.414 17.0001L26.707 18.2931C26.8892 18.4817 26.99 18.7343 26.9877 18.9965C26.9854 19.2587 26.8802 19.5095 26.6948 19.6949C26.5094 19.8803 26.2586 19.9855 25.9964 19.9877C25.7342 19.99 25.4816 19.8892 25.293 19.7071L24 18.4141L22.707 19.7071C22.5184 19.8892 22.2658 19.99 22.0036 19.9877C21.7414 19.9855 21.4906 19.8803 21.3052 19.6949C21.1198 19.5095 21.0146 19.2587 21.0123 18.9965C21.01 18.7343 21.1108 18.4817 21.293 18.2931L22.586 17.0001L21.293 15.7071C21.1055 15.5195 21.0002 15.2652 21.0002 15.0001C21.0002 14.7349 21.1055 14.4806 21.293 14.2931Z"
		fill="white"
	/>
</svg>

<style>
</style>
