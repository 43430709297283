<script>
	import { myUserStore, otherUsersStore } from "../services/store";
	import AskedByAvatar from "./AskedByAvatar.svelte";

	export let askedBy;

	let madebyDive = false;
	let usersList = [];

	$: otherUsers = $otherUsersStore;

	$: myUser = $myUserStore;

	$: if (otherUsers && myUser) {
		usersList = [];
		if (typeof askedBy !== "undefined" && askedBy.length > 0) {
			askedBy.forEach(el => {
				if (myUser.id == el) {
					let questionCreator = {
						userName: myUser.userName,
						profilePicture: myUser.profilePicture,
					};
					usersList = [...usersList, questionCreator];
				} else {
					otherUsers.forEach(user => {
						if (user.id == el) {
							let questionCreator = {
								userName: user.userName,
								profilePicture: user.profilePicture,
							};
							usersList = [...usersList, questionCreator];
						}
					});
				}
			});
			madebyDive = false;
		} else {
			madebyDive = true;
		}
	}
</script>

<div class="avatarContainer">
	{#if madebyDive}
		<div class="box" aria-label="Dive" data-microtip-position="right" role="tooltip">
			<img class="divelogo" src="/assets/svg/dive.svg" alt="dive" />
		</div>
	{:else if usersList && usersList.length > 0}
		{#each usersList as user}
			<div class="box">
				<AskedByAvatar userName={user.userName} src={user.profilePicture} />
			</div>
		{/each}
	{/if}
</div>

<style>
	.avatarContainer {
		width: 100%;
		height: 32px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.box {
		width: 32px;
		height: 32px;
		background: #ffffff;
		box-shadow: 2.63205px 2.63205px 29.8299px rgba(78, 78, 78, 0.25);
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: -16px;
	}
	.divelogo {
		width: 25px;
		height: 25px;
		border-radius: 50%;
		box-sizing: border-box;
		object-fit: cover;
		border: 2px solid #d23a47;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
