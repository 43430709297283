<script>
	// Import required libaries, services, pages, components
	import { onDestroy, onMount, afterUpdate } from "svelte";
	import {
		dbGameSessionRounds,
		dbGameSessionRoundValue,
		dbHost,
		dbUsers,
		listenOnFirebaseKey,
		user,
	} from "../services/database";
	import { myUserStore, otherUsersStore, usersStore } from "../services/store";
	import { getParams, isEqual, playSound } from "../services/utils";
	import { hostLeft, iAmHost, userLeft, reconnected } from "../services/notifier";
	import LoadingScreen from "./LoadingScreen.svelte";
	import LobbyScren from "./LobbyScren.svelte";
	import CardPickerScreen from "./CardPickerScreen.svelte";
	import QuestionDeckScreen from "./QuestionDeckScreen.svelte";
	import VotingScreen from "./VotingScreen.svelte";
	import CountingScreen from "./CountingScreen.svelte";
	import ResultScreen from "./ResultScreen.svelte";
	import GameOverScreen from "./GameOverScreen.svelte";
	import Notification from "../components/Notifications/index.svelte";

	// Variables, reactive snippets and helper functions
	let page;
	let users = [];
	let hostId;
	let roundValue;
	let timer;
	let oldOtherUserList = [];
	var dbPageKey;
	const userId = getParams("userId");
	let oldHostId;
	let bgColors = {
		black: "radial-gradient(38.97% 38.97% at 50% 61.03%, #36474f 0%, #151c1f 61.99%)",
		blue: "linear-gradient(180deg, #4839A4 22.06%, #291C74 100%)",
	};
	let backgroundColor;

	$: if (page === "LOADING_SCREEN" || page === "LOBBY_SCREEN") {
		backgroundColor = bgColors.black;
	} else if (
		page === "CARD_PICKER_SCREEN" ||
		page === "QUESTION_DECK_SCREEN" ||
		page === "VOTING_SCREEN" ||
		page === "COUNT_SCREEN"
	) {
		backgroundColor = bgColors.blue;
	} else if (page === "RESULT_SCREEN") {
		backgroundColor = bgColors.mix;
	}
	$: {
		if (user.id === hostId) {
			dbHost.onDisconnect().remove();
		}
	}

	function onPageChange(snap) {
		if (!snap.exists()) {
			page = "LOADING_SCREEN";
			return;
		}
		page = snap.val();
	}

	// Life cycles
	onMount(() => {
		// Event listeners
		listenOnFirebaseKey(dbUsers, val => {
			usersStore.set(val);
			users = Object.values(val);
			myUserStore.set(users.find(u => u.id === user.id));
		});

		listenOnFirebaseKey(dbHost, val => {
			if (hostId) {
				oldHostId = hostId;
				const oldHostName = users.find(user => user.id === hostId)?.userName;
				const newHostName = users.find(user => user.id === val)?.userName;
				let message = "";
				if (val == userId) {
					message = `${oldHostName || "Old Host"} has left the game and you are the new host`;
					console.log(message);
					iAmHost(message);
				} else {
					if (newHostName) {
						message = `${oldHostName || "Old Host"} has left the game and new host is ${newHostName}`;
					} else {
						message = `${oldHostName || "Old Host"} has left the game and new host has been assigned`;
					}
					console.log(message);
					hostLeft(message);
				}
			} else {
				oldHostId = val; //This is to get host id when the game starts initially as the if block does not executed when first tiem a host is getting picked
			}
			hostId = val;
		});

		dbGameSessionRoundValue.on("value", snap => {
			if (!snap.exists()) {
				return;
			}
			roundValue = snap.val();
			if (dbPageKey) {
				dbPageKey.off("value", onPageChange);
			}
			dbPageKey = dbGameSessionRounds.child(roundValue).child("page");
			dbPageKey.on("value", onPageChange);
		});
	});
	onMount(() => {
		timer = setInterval(() => {
			const allOtherUsers = users.filter(u => u.id !== user.id);
			const otherUserList = [];
			allOtherUsers.forEach(user => {
				if (user.online === true) {
					otherUserList.push({
						...user,
						online: true,
						connecting: false,
					});
				} else if (typeof user.online === "number") {
					if (Date.now() - user.online > 5000) {
						otherUserList.push({
							...user,
							online: false,
							connecting: false,
						});
					} else {
						otherUserList.push({
							...user,
							online: false,
							connecting: true,
						});
					}
				} else {
					otherUserList.push({
						...user,
						online: false,
						connecting: false,
					});
				}
			});
			if (!isEqual(otherUserList, oldOtherUserList)) {
				oldOtherUserList.forEach(oldUser => {
					otherUserList.forEach(otherUser => {
						if (
							oldUser.id === otherUser.id &&
							oldUser.online === true &&
							otherUser.online === false &&
							oldUser.id !== oldHostId
						) {
							let message = `${oldUser.userName} has left the game`;
							console.log(message);
							userLeft(message);
						} else if (
							oldUser.id === otherUser.id &&
							oldUser.online === false &&
							otherUser.online === true
						) {
							let message = `${oldUser.userName} has reconnected`;
							console.log(message);
							reconnected(message);
						}
					});
				});
				otherUsersStore.set(otherUserList);
				oldOtherUserList = otherUserList;
			}
		}, 1000);
	});
	afterUpdate(() => {
		if (page === "LOADING_SCREEN" || page === "LOBBY_SCREEN") {
			playSound("INTRO");
		} else if (
			page === "CARD_PICKER_SCREEN" ||
			page === "QUESTION_DECK_SCREEN" ||
			page === "VOTING_SCREEN" ||
			page === "COUNT_SCREEN"
		) {
			playSound("INTROSTOP");
			playSound("TRANSITION");
		} else if (page === "RESULT_SCREEN") {
			playSound("REVEAL");
		} else if (page === "GAME_OVER_SCREEN") {
			playSound("APPLAUSE");
		}
	});
	onDestroy(() => {
		clearInterval(timer);
	});
</script>

<div class="container" style="--bg-color: {backgroundColor}">
	<img class="bgSvg" src="/assets/svg/thumbs.svg" alt="thumbs svg" />

	<div class="pageDiv">
		{#if page === "LOADING_SCREEN"}
			<LoadingScreen />
		{:else if page === "LOBBY_SCREEN"}
			<LobbyScren />
		{:else if page === "CARD_PICKER_SCREEN"}
			<CardPickerScreen />
		{:else if page === "QUESTION_DECK_SCREEN"}
			<QuestionDeckScreen />
		{:else if page === "VOTING_SCREEN"}
			<VotingScreen />
		{:else if page === "COUNT_SCREEN"}
			<CountingScreen />
		{:else if page === "RESULT_SCREEN"}
			<ResultScreen />
		{:else if page === "GAME_OVER_SCREEN"}
			<GameOverScreen />
		{:else}
			<div />
		{/if}
	</div>
</div>

<Notification />

<style>
	/* CSS for 1080p */
	.container {
		width: 100%;
		height: 100%;
		position: relative;
		background: var(--bg-color);
	}
	.bgSvg {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 1;
	}

	.pageDiv {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 2;
	}

	/* CSS for 720p */
	@media screen and (max-width: 780px) {
	}
	:global(*) {
		box-sizing: border-box;
	}
</style>
