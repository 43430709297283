<script>
	import { otherUsersStore } from "../services/store";
	import { playSound } from "../services/utils.ts";
	import VotingRound from "./VotingRound.svelte";
	import PlayerInfoBox from "./PlayerInfoBox.svelte";
	import FlipCard from "./FlipCard.svelte";

	export let question;
	export let prevQuestion;
	export let miniRound;
	export let goNext = () => {};
	export let voteChosen = () => {};

	let otherUsers;
	let columnA;
	let columnB;
	let vote;

	$: if (miniRound >= 0 || miniRound < 5) {
		vote = null;
	}

	$: {
		otherUsers = $otherUsersStore;
		let half = Math.ceil(otherUsers.length / 2);
		columnA = otherUsers.slice(0, half);
		columnB = otherUsers.slice(half);
	}

	let pickUser = id => {
		vote = id;
		voteChosen(vote);
	};
	let nextRound = () => {
		goNext(vote);
		vote = null;
	};
	let nextClick = () => playSound("CLICK");
</script>

{#if question}
	<div class="container">
		<div class="cardBox">
			<FlipCard {question} {prevQuestion} />
			<VotingRound {miniRound} />
		</div>
		<div class="votingbox">
			<div class="column columnA">
				{#each columnA as user}
					<PlayerInfoBox {pickUser} {miniRound} {user} voted={vote == user.id ? true : false} />
				{/each}
			</div>
			<div class="column">
				{#each columnB as user}
					<PlayerInfoBox {pickUser} {miniRound} {user} voted={vote == user.id ? true : false} />
				{/each}
			</div>
		</div>
		<div on:mousedown={nextClick} on:click={nextRound} class="centered" class:picked={vote}>
			<img src="/assets/svg/next.svg" alt="next" />
		</div>
	</div>
{/if}

<style>
	.container {
		width: 70%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.cardBox {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		border-radius: 8px;
		margin-right: 16px;
	}
	.votingbox {
		min-width: 530px;
		height: auto;
		max-height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 10px;
		border-radius: 8px;
		background: rgba(28, 12, 49, 0.25);
	}
	.column {
		width: 50%;
		height: fit-content;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.columnA {
		margin-right: 8px;
	}
	.centered {
		width: 51px;
		height: 51px;
		position: fixed;
		right: 3%;
		top: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 99 !important;
		pointer-events: none;
		opacity: 0.5;
	}
	.picked {
		pointer-events: auto;
		cursor: pointer;
		opacity: 1;
	}
	::-webkit-scrollbar {
		display: none;
	}
	@media screen and (max-width: 800px), (max-height: 520px) {
		.container {
			width: 80%;
		}
		.votingbox {
			min-width: 0;
			width: 460px;
		}
		.cardBox {
			min-width: 0;
			width: 210px;
		}
		.centered {
			width: 35px;
			height: 35px;
			right: 3%;
		}
	}
</style>
