<script>
	import { ProfilePicture } from "da-components";

	export let user;
	export let voted = false;
</script>

<div class="container">
	<div class="info">
		<ProfilePicture userName={user?.userName} src={user?.profilePicture} size="25" fontSize="12" />
		<div class="name">{user.userName}</div>
	</div>
	<div class="votedSvg">
		{#if voted}
			<img src="/assets/svg/voted.svg" alt="voted" />
		{:else if !user.online}
			<img src="/assets/svg/disconnected.svg" alt="disconnected" />
		{/if}
	</div>
</div>

<style>
	.container {
		min-width: 261px;
		min-height: 62px;
		width: 300px;
		height: 71px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 8px 16px 8px 16px;
		background: rgba(241, 241, 241, 0.35);
		box-shadow: 3px 3px 34px rgba(78, 78, 78, 0.16);
		border-radius: 8px;
		margin: 4px 0px;
	}
	.info {
		height: 36px;
		width: calc(100% - 35px);
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.name {
		width: calc(100% - 35px);
		height: 36px;
		font-family: manrope;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 36px;
		color: #ffffff;
		display: inline-block;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin-left: 10px;
	}
	.votedSvg {
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 10px;
	}
	@media screen and (max-width: 780px), (max-height: 520px) {
		.container {
			min-width: 0;
			min-height: 0;
			max-width: 220px;
			height: 50px;
		}
		.name {
			font-size: 14px;
		}
		.votedSvg {
			width: 25px;
			height: 25px;
		}
	}
</style>
