<script>
</script>

<svg width="100%" height="100%" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
	<rect width="34" height="34" rx="17" fill="white" fill-opacity="0.16" />
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M10.08 14H10C9.46957 14 8.96086 14.2108 8.58579 14.5858C8.21071 14.9609 8 15.4696 8 16V18C8 18.5305 8.21071 19.0392 8.58579 19.4143C8.96086 19.7893 9.46957 20 10 20H10.08C10.3686 19.9999 10.6537 20.0622 10.9159 20.1827C11.1781 20.3032 11.4111 20.479 11.599 20.698L15.241 24.948C15.845 25.652 17 25.225 17 24.297V9.70304C17 8.77504 15.845 8.34804 15.24 9.05304L11.6 13.301C11.4121 13.5204 11.179 13.6964 10.9166 13.8171C10.6542 13.9378 10.3688 14.0002 10.08 14ZM23.636 9.27504C23.5414 9.18177 23.4291 9.10829 23.3057 9.05891C23.1823 9.00953 23.0504 8.98523 22.9175 8.98743C22.7847 8.98963 22.6536 9.01828 22.5319 9.07172C22.4102 9.12517 22.3005 9.20232 22.209 9.29867C22.1174 9.39503 22.0461 9.50865 21.999 9.63291C21.9519 9.75716 21.93 9.88956 21.9347 10.0224C21.9394 10.1552 21.9704 10.2857 22.0261 10.4063C22.0818 10.527 22.161 10.6353 22.259 10.725C25.914 14.197 25.914 19.803 22.259 23.275C22.161 23.3647 22.0818 23.4731 22.0261 23.5937C21.9704 23.7144 21.9394 23.8449 21.9347 23.9777C21.93 24.1105 21.9519 24.2429 21.999 24.3672C22.0461 24.4914 22.1174 24.6051 22.209 24.7014C22.3005 24.7978 22.4102 24.8749 22.5319 24.9284C22.6536 24.9818 22.7847 25.0104 22.9175 25.0126C23.0504 25.0149 23.1823 24.9905 23.3057 24.9412C23.4291 24.8918 23.5414 24.8183 23.636 24.725C28.121 20.465 28.121 13.535 23.636 9.27504ZM20.689 12.075C20.4967 11.8923 20.2397 11.7934 19.9745 11.8002C19.7094 11.8069 19.4577 11.9188 19.275 12.111C19.0923 12.3033 18.9934 12.5603 19.0002 12.8255C19.0069 13.0907 19.1187 13.3423 19.311 13.525C21.338 15.45 21.338 18.55 19.311 20.475C19.2158 20.5655 19.1393 20.6739 19.086 20.7939C19.0327 20.9139 19.0035 21.0433 19.0002 21.1746C18.9968 21.3059 19.0194 21.4366 19.0665 21.5591C19.1137 21.6817 19.1845 21.7938 19.275 21.889C19.4577 22.0813 19.7094 22.1931 19.9745 22.1999C20.1058 22.2032 20.2365 22.1807 20.3591 22.1335C20.4817 22.0864 20.5938 22.0155 20.689 21.925C23.546 19.211 23.546 14.789 20.689 12.075Z"
		fill="white"
	/>
</svg>

<style>
</style>
