<script>
	import { onMount } from "svelte";
	import { dbHost, listenOnFirebaseKey } from "../services/database";

	import { myUserStore, usersStore } from "../services/store";

	import ResultCard from "./ResultCard.svelte";
	import ResultInfo from "./ResultInfo.svelte";
	import User from "./svg/User.svelte";

	export let resultRound;
	export let cardData;
	export let result;

	let hostId;
	$: host = $usersStore[hostId];

	onMount(() => {
		listenOnFirebaseKey(dbHost, val => {
			hostId = val;
		});
	});

	$: amIHost = hostId === $myUserStore?.id;
</script>

<div class="container">
	<div class="heading">
		<div class="text">
			<span class="highlight">Want to make it fun?</span> Share stories of why you voted the person! 🤣
		</div>
		{#if !amIHost}
			<div class="text">
				After that <span class="highlight">{host?.userName}(Host)</span> will be moving to next card
			</div>
		{/if}
	</div>
	<div class="content">
		<div class="card">
			{#if cardData}
				<ResultCard {resultRound} {cardData} />
			{/if}
		</div>
		<div class="storyArea"><ResultInfo {resultRound} {result} /></div>
	</div>
</div>

<style>
	.container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.heading {
		width: 100%;
		height: 20%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.text {
		font-family: manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 25px;
		text-align: center;
		color: #ffffff;
	}
	.highlight {
		color: #ffe55b;
	}
	.content {
		width: 100%;
		height: 80%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 30px;
	}
	.card {
		width: 28%;
		height: 100%;
	}
	.storyArea {
		width: 67%;
		height: 100%;
	}

	@media screen and (max-width: 1100px), (max-height: 600px) {
		.card {
			width: 31%;
			height: 100%;
		}
		.storyArea {
			width: 67%;
			height: 100%;
		}
	}
	@media screen and (max-width: 780px), (max-height: 520px) {
		.content {
			margin-bottom: 10px;
		}
		.text {
			font-size: 18px;
			line-height: 18px;
		}
		.card {
			width: 28%;
			height: 100%;
		}
		.storyArea {
			width: 67%;
			height: 100%;
		}
	}
</style>
