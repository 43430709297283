<script>
	export let item;
	export let showCardBack;
	export let onChange = () => {};

	let value = "";

	$: onChange(value);
</script>

<div class="card">
	<div class="container">
		<div class="cardHeader" />
		<div class="cardContent">
			<span class="red">WHO’S</span>
			<span class="black">MOST LIKELY TO...</span>
		</div>
	</div>
</div>

<div class="card-back" class:conceal-answer={showCardBack}>
	<div class="container">
		<div class="cardHeader-back" />
		<div class="title"><span class="red-back">WHO’S</span><span class="black-back">MOST LIKELY TO...</span></div>
		<div class="cardContent-back">
			{#if item}
				<span class="question">...{item.value}</span>
			{:else}
				<div class="question2">
					<textarea
						class="textarea"
						maxlength="45"
						bind:value
						type="text"
						id="inputArea"
						placeholder="Enter your own prompt"
					/>
					{#if value?.length >= 30}
						<span class="limit">{value.length}/45 character limit</span>
					{/if}
				</div>
			{/if}
		</div>
	</div>
</div>

<style>
	.card,
	.card-back {
		width: 222px;
		height: 329px;
		max-width: 222px;
		max-height: 329px;
		border-radius: 4.44257px;
		background: #ffffff;
		position: absolute;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}
	.card-back {
		transform: rotateY(180deg);
		z-index: 15;
	}
	.container {
		width: 100%;
		height: 100%;
		position: relative;
	}
	@keyframes revealTextSlowly {
		to {
			color: white;
		}
	}
	.conceal-answer {
		animation: revealTextSlowly 0.3s forwards;
	}
	.cardHeader {
		width: 100%;
		height: 28px;
		max-height: 35px;
		position: absolute;
		background: #d23a47;
		border-top-left-radius: 4.44257px;
		border-top-right-radius: 4.44257px;
		z-index: 10;
	}
	.cardHeader-back {
		width: 100%;
		height: 28px;
		max-height: 28px;
		position: absolute;
		background: #d23a47;
		border-top-left-radius: 4.44257px;
		border-top-right-radius: 4.44257px;
		z-index: 10;
	}
	.title {
		width: 100%;
		height: 38px;
		margin-top: 37px;
		position: absolute;
		z-index: 11;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.red-back {
		width: 100%;
		height: 14px;
		font-family: MODULAR-B;
		font-style: normal;
		font-weight: normal;
		font-size: 13.9831px;
		line-height: 13px;
		color: #d23a47;
		padding-left: 10px;
		text-align: left;
		margin-bottom: 4px;
	}
	.black-back {
		width: 100%;
		height: 23px;
		font-family: MODULAR-B;
		font-style: normal;
		font-weight: normal;
		font-size: 23.3212px;
		line-height: 19px;
		color: #000000;
		padding-left: 10px;
		text-align: left;
	}
	.cardContent {
		width: 100%;
		height: 100%;
		border-bottom-left-radius: 4.44257px;
		border-bottom-right-radius: 4.44257px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.cardContent-back {
		width: 100%;
		height: 100%;
		border-bottom-left-radius: 4.44257px;
		border-bottom-right-radius: 4.44257px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.red {
		width: 100%;
		height: 25px;
		font-family: MODULAR-B;
		font-style: normal;
		font-weight: normal;
		font-size: 26.9489px;
		line-height: 25px;
		color: #cf3946;
		padding-left: 18px;
		text-align: left;
	}
	.black {
		width: 100%;
		height: auto;
		font-family: MODULAR-B;
		font-style: normal;
		font-weight: normal;
		font-size: 54.9344px;
		line-height: 50px;
		padding-left: 18px;
		color: #000000;
		text-align: left;
	}
	.question,
	.question2 {
		width: 100%;
		height: auto;
		font-family: manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 23.5921px;
		line-height: 118%;
		padding: 0 10px 0 10px;
		color: #121212;
		text-align: left;
		transform: translate(0, 20%);
	}
	.question2 {
		transform: translate(0, 30%);
	}
	.limit {
		margin-top: 8px;
		font-family: Manrope;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		display: flex;
		align-items: center;
		color: rgba(54, 71, 79, 0.75);
	}
	.textarea {
		width: 100%;
		height: 100px;
		font-family: manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 23.5921px;
		line-height: 118%;
		color: #121212;
	}
	textarea {
		border: none;
		outline: none;
		resize: none;
		overflow: hidden;
		margin: 0;
		padding: 0;
	}
	/* changed */
	@media screen and (max-width: 780px), (max-height: 520px) {
		.card,
		.card-back {
			width: 133px;
			height: 197px;
			max-width: 222px;
			max-height: 329px;
		}
		.cardHeader {
			height: 21px;
			max-height: 28px;
		}
		.cardHeader-back {
			height: 14px;
			max-height: 17px;
		}
		.red {
			height: 18px;
			font-size: 20px;
			line-height: 18px;
			padding-left: 12px;
		}
		.black {
			font-size: 40px;
			line-height: 40px;
			padding-left: 12px;
		}
		.title {
			height: 21px;
			margin-top: 21px;
		}
		.red-back {
			height: 12px;
			font-size: 12px;
			line-height: 12px;
		}
		.black-back {
			height: 16px;
			font-size: 16px;
			line-height: 16px;
		}
		.question {
			font-size: 14px;
		}
		.textarea {
			font-size: 14px;
			height: 65px;
		}
		.limit {
			font-size: 12px;
		}
	}
</style>
