<script>
	import { myUserStore, otherUsersStore } from "../services/store";
	import Bar from "../components/customBarGraph/index.svelte";
	import Gif from "./image/ResultGif.svelte";

	export let resultRound;
	export let result;

	let myUser;
	let otherUsers;
	let allUsers;
	let resultOfThisRound;

	$: {
		otherUsers = $otherUsersStore;
		myUser = $myUserStore;
		allUsers = [...otherUsers, myUser];
	}

	$: if (result) {
		resultOfThisRound = result[resultRound];
	}
</script>

<div class="infoBoxContainer">
	<div class="info">
		<div class="cardHeader" />
		<div class="container">
			<div class="content">
				<div class="heading"><span class="text">🎙️ Spill the <span class="redText">BEANS!</span></span></div>
				<div class="gifhy"><Gif /></div>
			</div>
			<div class="bar">
				{#if resultOfThisRound && allUsers.length > 1}
					{#key resultOfThisRound}
						<Bar {resultOfThisRound} {allUsers} />
					{/key}
				{/if}
			</div>
		</div>
	</div>
</div>

<style>
	.infoBoxContainer {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	.info {
		width: 100%;
		height: 100%;
		border-radius: 6px;
		background: #ffffff;
		border-radius: 10.1809px;
	}
	.cardHeader {
		width: 100%;
		height: 21px;
		max-height: 21px;
		background: #36474f;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
	.container {
		width: 100%;
		height: calc(100% - 21px);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 24px;
	}
	.bar {
		width: 55%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.content {
		width: 45%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-right: 24px;
	}
	.heading {
		width: 100%;
		height: 13%;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	.text {
		font-family: MODULAR-B;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		line-height: 24px;
		color: #36474f;
		margin-bottom: 4px;
	}
	.redText {
		color: #d23a47;
	}
	.gifhy {
		width: 100%;
		height: 87%;
		border-radius: 8px;
	}
	@media screen and (max-width: 780px), (max-height: 520px) {
		.cardHeader {
			height: 15px;
			max-height: 15px;
		}
		.container {
			max-height: calc(100% - 15px);
			padding: 16px;
		}
		.text {
			font-size: 16px;
			line-height: 16px;
		}
		.content {
			margin-right: 16px;
		}
	}
</style>
