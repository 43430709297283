<script>
	import { onMount } from "svelte";
	import { otherUsersStore } from "../services/store";
	import { user, dbHost, listenOnFirebaseKey } from "../services/database";
	import OnlineList from "../components/OnlineList/index.svelte";
	import User from "../components/svg/User.svelte";

	let AllOnline, hostId;

	$: otherUsers = $otherUsersStore;

	$: onlineUsers = otherUsers.filter(user => user.online);

	$: {
		if (onlineUsers.length >= 0 && user) {
			AllOnline = [user, ...onlineUsers];
		}
	}

	onMount(() => {
		listenOnFirebaseKey(dbHost, val => {
			hostId = val;
		});
	});
</script>

<OnlineList {AllOnline} {hostId}>
	<div class="container">
		<div class="countBox">
			<div class="svg"><User /></div>
			<div class="text"><span>{onlineUsers.length + 1}</span></div>
		</div>
	</div>
</OnlineList>

<style>
	.container {
		display: flex;
		justify-content: flex-end;
	}
	.countBox {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 8px 16px;
		width: 73px;
		height: 36px;
		background: #ffffff;
		border-radius: 90px;
	}
	.svg {
		width: 20px;
		height: 20px;
	}
	.text {
		width: 20px;
		height: 20px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		font-family: MODULAR-B;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 18px;
		text-align: center;
		color: #302286;
	}
	@media screen and (max-width: 780px) {
		.countBox {
			height: 32px;
		}
		.svg,
		.text {
			width: 16px;
			height: 16px;
		}
		.text {
			font-size: 14px;
			line-height: 14px;
		}
	}
</style>
