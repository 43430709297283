<script>
	import { onMount } from "svelte";
	import {
		dbHost,
		dbPageKey,
		dbRoundQuestions,
		dbroundvotingRulesExpireyAt,
		listenOnFirebaseKey,
		getRoundValue,
	} from "../services/database";
	import { user } from "../services/database";
	import { otherUsersStore } from "../services/store";
	import VoterInfoBox from "./VoterInfoBox.svelte";
	import PieTimer2 from "../components/PieTimer2/Big.svelte";

	export let expiryAt;
	export let time;
	let otherUsers;
	let onlineUsers;
	let playersList = [];
	let myUser = {};
	let columnA;
	let columnB;
	let pickedQuestions;
	let votersLeft;
	let isHost;

	$: {
		otherUsers = $otherUsersStore;
		onlineUsers = otherUsers.filter(user => user.online);
		myUser = { ...user, online: true };
		playersList = [...otherUsers, myUser];
		let half = Math.ceil(playersList.length / 2);
		columnA = playersList.slice(0, half);
		columnB = playersList.slice(half);
	}
	$: {
		if (onlineUsers.length > 0) {
			if (pickedQuestions) {
				let count = 0;
				onlineUsers.forEach(({ id }) => {
					if (!pickedQuestions[id]) {
						count += 1;
					}
				});
				votersLeft = count;
			} else {
				votersLeft = onlineUsers.length;
			}
		} else if (onlineUsers.length === 0) {
			votersLeft = onlineUsers.length;
		}
	}

	$: if (votersLeft === 0 && isHost) {
		dbPageKey().set("QUESTION_DECK_SCREEN");
		let timeforThisround;
		let roundId = getRoundValue();
		timeforThisround = roundId == 1 ? 15 : 3;
		dbroundvotingRulesExpireyAt().set(new Date(new Date().getTime() + timeforThisround * 1000).getTime());
	}

	onMount(() => {
		listenOnFirebaseKey(dbRoundQuestions(), val => (pickedQuestions = val));
		listenOnFirebaseKey(dbHost, val => {
			if (val === user.id) {
				isHost = true;
			} else {
				isHost = false;
			}
		});
	});
</script>

{#if time <= 30}
	<div class="waitingArea">
		<div class="textBox"><span class="text">Waiting for {votersLeft} others to share their question</span></div>
		{#if pickedQuestions}
			<div class="waitingAreaContainer">
				<div class="column">
					{#each columnA as user}
						<VoterInfoBox {user} voted={pickedQuestions[user.id] ? true : false} />
					{/each}
				</div>
				<div class="timerDiv">
					{#if time <= 30 && time > 0}
						<div class="countDown">
							<PieTimer2 {expiryAt} />
						</div>
					{:else if time <= 0}
						<div class="countDown animate">
							<img src="/assets/svg/timesUp.svg" alt="timesup" />
						</div>
					{/if}
				</div>
				<div class="column">
					{#each columnB as user}
						<VoterInfoBox {user} voted={pickedQuestions[user.id] ? true : false} />
					{/each}
				</div>
			</div>
		{/if}
	</div>
{/if}

<style>
	.waitingArea {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.textBox {
		width: 100%;
		height: 10%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
	}
	.text {
		font-family: manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 20px;
		text-align: center;
		color: #ffffff;
		margin-bottom: 11px;
	}
	.waitingAreaContainer {
		width: 90%;
		height: 90%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
	}
	.column {
		width: 350px;
		min-width: 191px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.timerDiv {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.countDown {
		margin: 0 20px 0 20px;
		min-width: 200px;
		min-height: 200px;
		width: 300px;
		height: 300px;
	}
	.animate {
		animation: zoom-in-zoom-out 1.5s ease infinite;
	}
	::-webkit-scrollbar {
		display: none;
	}
	@keyframes zoom-in-zoom-out {
		0% {
			transform: scale(1, 1);
		}
		50% {
			transform: scale(1.25, 1.25);
		}
		100% {
			transform: scale(1, 1);
		}
	}
	@media screen and (max-width: 780px), (max-height: 520px) {
		.textBox {
			height: 8%;
		}
		.waitingAreaContainer {
			height: 92%;
		}
		.text {
			font-size: 18px;
			line-height: 18px;
		}
		.column {
			min-width: 0;
			max-width: 200px;
		}
		.countDown {
			width: 150px;
			height: 150px;
		}
	}
</style>
