<script>
	// your script goes here
</script>

<svg width="100%" height="100%" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M6.25 5.91663C6.25 7.98413 7.9325 9.66663 10 9.66663C12.0675 9.66663 13.75 7.98413 13.75 5.91663C13.75 3.84913 12.0675 2.16663 10 2.16663C7.9325 2.16663 6.25 3.84913 6.25 5.91663ZM16.6667 18H17.5V17.1666C17.5 13.9508 14.8825 11.3333 11.6667 11.3333H8.33333C5.11667 11.3333 2.5 13.9508 2.5 17.1666V18H16.6667Z"
		fill="#302286"
	/>
</svg>

<style>
	/* your styles go here */
</style>
