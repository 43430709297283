import { getGameSessionId } from "./utils";

function callPostApi(url, body) {
	return fetch(url, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	});
}

export async function getAllThemes() {
	const rawResponse = await callPostApi("/getAllThemes", { roomIdSessionId: getGameSessionId() });
	const content = await rawResponse.json();
	return content;
}

export async function setRandomThemeItem() {
	const rawResponse = await callPostApi("/setRandomThemeItem", { roomIdSessionId: getGameSessionId() });
	const content = await rawResponse.text();
	return content;
}

export async function updateToLeaderboardPage(roundValue, userId) {
	const rawResponse = await callPostApi("/updateToLeaderboardPage", {
		roomIdSessionId: getGameSessionId(),
		roundValue,
		lierUserId: userId,
	});
	const content = await rawResponse.text();
	return content;
}

export async function fetchNewData(url) {
	const rawResponse = await callPostApi("/fetchNewData", { url });
	const content = await rawResponse.json();
	return content;
}
