<script>
	import { playSound } from "../services/utils.ts";
	export let text;
	export let isArrow;
	export let disabled = true;
	export let btnHandler = () => {};

	let backgroundColors = ["#ffffff", "#D23A47"];
	let textColors = ["#000000", "#FFFFFF"];
	let backgroundColor;
	let textColor;

	$: if (text === "Submit") {
		backgroundColor = backgroundColors[1];
		textColor = textColors[1];
	} else {
		backgroundColor = backgroundColors[0];
		textColor = textColors[0];
	}

	let buttonClick = () => playSound("CLICK");
</script>

<button
	class="btnStart"
	{disabled}
	class:disabled
	on:mousedown={buttonClick}
	on:click={btnHandler}
	style="--bg-color: {backgroundColor}; --text-color: {textColor}"
>
	<span class="btnText">{text}</span>
	{#if isArrow}
		<div class="svg"><img src="/assets/svg/arrow.svg" alt="arrow" /></div>
	{/if}
</button>

<style>
	.btnStart {
		width: auto;
		height: auto;
		min-width: 148px;
		min-height: 56px;
		background: var(--bg-color);
		border-radius: 8px;
		padding: 8px 12px 8px 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		cursor: pointer;
	}
	.btnText {
		font-family: manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 24px;
		color: var(--text-color);
	}
	.svg {
		width: 24px;
		height: 24px;
		margin-left: 8px;
	}
	.disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}
	@media only screen and (max-width: 780px) {
		.btnStart {
			min-width: 10;
			min-height: 0;
			width: 100px;
			height: 40px;
			padding: 4px;
		}
		.btnText {
			font-size: 14px;
			line-height: 18px;
		}
		.svg {
			width: 18px;
			height: 18px;
			margin-left: 4px;
		}
	}
</style>
