<script>
	import { onMount, onDestroy } from "svelte";
	import { dbroundvotingRulesExpireyAt, getRoundValue } from "../services/database";
	import GameTitle from "../components/GameTitle.svelte";
	import RoundInfo from "../components/RoundInfo.svelte";
	import ControlBar from "../components/controlBar/index.svelte";
	import Rule from "../components/votingRules/index.svelte";

	let expiryAt, time, expiryTimer;

	let round = getRoundValue();

	onMount(async () => {
		await dbroundvotingRulesExpireyAt()
			.once("value")
			.then(val => {
				expiryAt = val.val();
			});
		expiryTimer = setInterval(() => {
			time = Math.round((expiryAt - new Date().getTime()) / 1000);
		}, 1000);
	});
	onDestroy(() => {
		clearInterval(expiryTimer);
	});
</script>

<div class="deckScreen">
	{#if round === 1 && time <= 12 && time > 0}
		<div class="info">
			<div class="rule">
				<Rule animationTime={time} />
			</div>
		</div>
	{/if}

	<div class="deckContainer">
		<div class="GameTitle">
			<GameTitle />
			<div class="controlbar"><ControlBar showInfoIcon={false} /></div>
			<div class="roundInfo">
				<RoundInfo />
			</div>
		</div>
		<div class="body">
			<div class="deck">
				<div class="deckSvg">
					<img src="/assets/svg/deck.svg" alt="deck" />
				</div>
			</div>
			<div class="cards">
				{#each Array(5) as item}
					<div class="cardSvg">
						<img src="/assets/svg/card.svg" alt="card" />
					</div>
				{/each}
			</div>
		</div>
		<div class="footer"><span class="text">Picking Questions at Random & then the voting begins</span></div>
	</div>
</div>

<style>
	.deckScreen {
		width: 100%;
		height: 100%;
	}
	.info {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		z-index: 4;
		background-color: rgba(0, 0, 0, 0.5);
	}
	.rule {
		width: 376px;
		height: 351px;
	}
	.deckContainer {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: absolute;
		z-index: 2;
	}
	.GameTitle {
		width: 100%;
		height: 15%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: relative;
	}
	.controlbar {
		position: absolute;
		left: 48px;
	}
	.roundInfo {
		position: absolute;
		right: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.body {
		width: 100%;
		height: 75%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.deck {
		width: 100%;
		min-height: 157px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.deckSvg {
		width: 331px;
		height: 235px;
		min-width: 221px;
		min-height: 157px;
	}
	.cards {
		height: fit-content;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}
	.cardSvg {
		width: 146px;
		height: 213px;
		min-width: 117px;
		min-height: 171px;
		margin: 5px 20px;
	}
	.footer {
		width: 100%;
		height: 10%;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	.text {
		font-family: manrope;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		line-height: 20px;
		color: #ffffff;
	}
	@media screen and (max-width: 780px), (max-height: 600px) {
		.rule {
			width: 223px;
			height: 211px;
		}
		.GameTitle {
			height: 15%;
		}
		.body {
			width: 100%;
			height: 82%;
		}
		.deck {
			height: 125px;
		}
		.deckSvg {
			min-width: 0;
			min-height: 0;
			max-width: 175px;
			max-height: 125px;
		}
		.cards {
			width: 75%;
			margin-top: -10px;
			margin-bottom: 5px;
		}
		.cardSvg {
			min-width: 0;
			min-height: 0;
			max-width: 85px;
			max-height: 125px;
		}
		.footer {
			height: 8%;
		}
		.text {
			font-size: 16px;
			line-height: 16px;
		}
	}
</style>
