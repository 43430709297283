<script>
	import "microtip/microtip.css";
	import { tweened } from "svelte/motion";
	import { cubicOut } from "svelte/easing";
	export let user;
	export let winner = false;
	export let pos;

	$: if (user && user.barSize) {
		let size = user.barSize;
		progress.set(size);
	}

	const progress = tweened(0, {
		duration: 1500,
		easing: cubicOut,
	});
</script>

<div
	class="line"
	class:winner
	style="width: {$progress}px;"
	aria-label={user.votedUserName}
	data-microtip-position={pos}
	role="tooltip"
>
	<span class="name">{user.votedUserName}</span>
</div>

<style>
	.line {
		width: 132px;
		height: 32px;
		background: #786bc2;
		border-radius: 3px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.name {
		width: calc(100% - 35px);
		font-family: Manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 11.0843px;
		line-height: 18px;
		display: flex;
		align-items: center;
		letter-spacing: 1.66265px;
		text-transform: uppercase;
		color: #ffffff;
		margin-left: 8px;
		display: inline-block;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.winner {
		background: #d23a47;
		height: 40px;
	}
	@media screen and (max-width: 780px), (max-height: 520px) {
		.line {
			height: 24px;
		}
		.winner {
			height: 30px;
		}
	}
</style>
