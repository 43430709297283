<script>
	export let src;
	export let userName;
	let brokenlink = false;
	let backgroundColors = [
		"linear-gradient(138.65deg, #2623D7 0%, #10B6DB 94.38%)",
		"linear-gradient(138.43deg, #FFD600 0%, #F10057 100.36%)",
		"linear-gradient(140.65deg, #FF0C0C 8.11%, #0019FF 100%)",
	];
	const color = Math.floor(Math.random() * backgroundColors.length);

	let handleError = () => {
		brokenlink = true;
	};
</script>

{#if src && !brokenlink}
	<div class="imageContainer">
		<img class="profilePicture" {src} on:error={handleError} alt="profile" />
	</div>
{:else}
	<div class="fakeProfilePicture" style="--bg-color: {backgroundColors[color]}">
		{userName[0]}
	</div>
{/if}

<style>
	.imageContainer {
		width: 25px;
		height: 25px;
		border-radius: 50%;
	}
	.profilePicture {
		width: 25px;
		height: 25px;
		border-radius: 50%;
		box-sizing: border-box;
		object-fit: cover;
	}
	.fakeProfilePicture {
		width: 25px;
		height: 25px;
		font-family: Manrope;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: #ffffff;
		border-radius: 50%;
		background: var(--bg-color);
		object-fit: cover;
	}
	@media screen and (max-width: 780px), (max-height: 520px) {
		.imageContainer,
		.profilePicture,
		.fakeProfilePicture {
			width: 20px;
			height: 20px;
		}
	}
</style>
