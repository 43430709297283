<script>
	import { onMount } from "svelte";
	import { dbTheme, listenOnFirebaseKey } from "../services/database";

	const defaultTheme = {
		icon: "🎁",
		name: "Default theme",
		description: "Let us pick a theme for you!",
		th_id: "default",
	};
	let themeData;

	onMount(async () => {
		await dbTheme().once("value", async snap => {
			if (!snap.exists()) {
				console.log("no theme");
				await dbTheme().set(defaultTheme);
			}
		});
		listenOnFirebaseKey(dbTheme(), val => (themeData = val));
	});
</script>

{#if themeData}
	<div class="hostPickedContainer">
		<div class="textContainer"><span class="text">Host has picked</span></div>
		<div class="themeContainer">
			<div class="themeName">
				<div class="emoji">{themeData.icon}</div>
				<div class="name">{themeData.name}</div>
			</div>
			<div class="themeDesc">{themeData.description}</div>
		</div>
	</div>
{/if}

<style>
	.hostPickedContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		max-width: 561px;
		max-height: 224px;
		background: #36474f;
		border: 1px solid #638b9f;
		box-sizing: border-box;
		border-radius: 12px;
	}
	.textContainer {
		width: 100%;
		height: 25%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
	}

	.text {
		width: 100%;
		height: 24px;
		font-family: MODULAR-B;
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
		line-height: 102%;
		text-align: center;
		color: #ffffff;
	}

	.themeContainer {
		width: 100%;
		height: 75%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding: 0px;
		margin: 24px 0px 0px 0px;
	}
	.themeName {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 0px;
	}

	.emoji {
		font-family: Manrope;
		font-style: normal;
		font-weight: 800;
		font-size: 53px;
		line-height: 72px;
		display: flex;
		align-items: center;
		text-align: center;
		color: #ffffff;
	}

	.name {
		font-family: MODULAR-B;
		font-style: normal;
		font-weight: normal;
		font-size: 48px;
		line-height: 72px;
		text-align: center;
		color: #ffffff;
		margin: 0 16px 0 0;
	}

	.themeDesc {
		width: 100%;
		font-family: Manrope;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 20px;
		text-align: center;
		color: #ffffff;
		margin: 8px 0px;
	}

	@media screen and (max-width: 780px) {
		.text {
			height: 20px;
			font-size: 20px;
		}
		.name {
			font-size: 30px;
		}
		.emoji {
			font-size: 36px;
			line-height: 50px;
		}
	}
</style>
