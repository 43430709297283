<script>
	import { onMount, onDestroy } from "svelte";
	import {
		user,
		dbThemeItems,
		dbroundcardPickerExpiryAt,
		dbRoundQuestions,
		listenOnFirebaseKey,
	} from "../services/database";
	import { shuffleArray, preLoadGifs } from "../services/utils.ts";
	import GameTitle from "../components/GameTitle.svelte";
	import BaseButton from "../components/BaseButton.svelte";
	import RoundInfo from "../components/RoundInfo.svelte";
	import CardPickerBox from "../components/CardPickerBox.svelte";
	import WaitingArea from "../components/WaitingArea.svelte";
	import ControlBar from "../components/controlBar/index.svelte";
	import PieTimer2 from "../components/PieTimer2/Small.svelte";

	let disabled = true;
	let shuffledArray;
	let items = [];
	let itemsDisplayed = [];
	let chosenQuestion = null;
	let expiryAt;
	let time;
	let expiryTimer;
	let showWaiting = false;

	$: if (items.length > 0) {
		shuffledArray = shuffleArray(items);
		itemsDisplayed = [shuffledArray[0], shuffledArray[1]];
	}

	$: if (chosenQuestion && chosenQuestion.value !== "") {
		disabled = false;
	} else {
		disabled = true;
	}

	$: if (time && time < -2) {
		let autoSubmitQuestion;
		if (chosenQuestion && chosenQuestion.value !== "") autoSubmitQuestion = chosenQuestion;
		else autoSubmitQuestion = shuffledArray?.[2];
		dbRoundQuestions()
			.child(`${user.id}`)
			.once("value")
			.then(val => {
				if (val.exists()) {
				} else if (autoSubmitQuestion) {
					dbRoundQuestions().child(`${user.id}`).set(autoSubmitQuestion);
				}
			});
		showWaiting = true;
	}

	let chooseQuestion = value => {
		chosenQuestion = value;
	};

	let submitCard = async () => {
		if (time && time > 0) {
			dbRoundQuestions()
				.child(`${user.id}`)
				.once("value")
				.then(val => {
					if (val.exists()) {
					} else {
						dbRoundQuestions().child(`${user.id}`).set(chosenQuestion);
					}
				});
		}
		showWaiting = true;
	};

	onMount(() => {
		listenOnFirebaseKey(dbThemeItems(), val => (items = val));
		preLoadGifs("voting rules");
		dbroundcardPickerExpiryAt()
			.once("value")
			.then(val => {
				expiryAt = val.val();
			});
		expiryTimer = setInterval(() => {
			time = Math.round((expiryAt - new Date().getTime()) / 1000);
		}, 1000);
	});
	onDestroy(() => {
		clearInterval(expiryTimer);
	});
</script>

<div class="cardPickerScreen">
	{#if itemsDisplayed && !showWaiting && time > 0}
		<div class="cardPickerContainer">
			<div class="GameTitle">
				<div class="controlbar"><ControlBar showInfoIcon={false} /></div>
				<GameTitle />
				<div class="roundInfo">
					<RoundInfo />
					{#if time <= 30 && time > 0}
						<PieTimer2 {expiryAt} />
					{/if}
				</div>
			</div>
			<div class="lobbyBody"><CardPickerBox {chooseQuestion} {itemsDisplayed} /></div>
			<div class="lobbyBtn">
				<BaseButton text={"Submit"} isArrow={false} {disabled} btnHandler={submitCard} />
			</div>
		</div>
	{:else}
		<div class="cardPickerContainer">
			<div class="GameTitle">
				<div class="controlbar"><ControlBar showInfoIcon={false} /></div>
				<GameTitle />
				<div class="roundInfo">
					<RoundInfo />
				</div>
			</div>
			<div class="lobbyWaitingBody"><WaitingArea {expiryAt} {time} /></div>
		</div>
	{/if}
</div>

<style>
	.cardPickerScreen {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.cardPickerContainer {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.GameTitle {
		width: 100%;
		height: 15%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: relative;
	}
	.controlbar {
		position: absolute;
		left: 48px;
	}
	.roundInfo {
		position: absolute;
		right: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.lobbyBody {
		width: 100%;
		height: 70%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 16px;
	}
	.lobbyBtn {
		width: 100%;
		height: 15%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.lobbyWaitingBody {
		width: 100%;
		height: 85%;
		min-height: 85%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	@media screen and (max-width: 780px) {
		.GameTitle {
			height: 25%;
		}
	}

	@media screen and (max-width: 640px) {
		.controlbar {
			left: 16px;
		}
		.roundInfo {
			display: none;
		}
	}
</style>
