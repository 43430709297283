<script>
	import GameIcon from "../components/svg/GameIcon.svelte";
</script>

<div class="headerContainer">
	<div class="iconContainer">
		<GameIcon />
	</div>
	<div class="nameContainer"><span>Most Likely To</span></div>
</div>

<style>
	.headerContainer {
		width: 80%;
		height: 48px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 0px;
	}
	.iconContainer {
		width: 48px;
		height: 48px;
	}
	.nameContainer {
		font-family: Manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		line-height: 42px;
		color: #ffffff;
		margin: 0 16px 0 16px;
	}

	@media screen and (max-width: 780px) {
		.headerContainer,
		.iconContainer {
			height: 30px;
		}
		.iconContainer {
			width: 30px;
		}
		.nameContainer {
			font-size: 24px;
			line-height: 28px;
		}
	}
</style>
