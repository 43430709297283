<script>
	import { dbResultRound, dbPageKey } from "../services/database";
	import { playSound } from "../services/utils";

	let next = () => playSound("CLICK");

	let onClick = async () => {
		await dbResultRound()
			.once("value")
			.then(round => {
				let count = round.val();
				if (count < 4) {
					dbResultRound().set(count + 1);
				} else {
					dbPageKey().set("GAME_OVER_SCREEN");
				}
			});
	};
</script>

<div class="container">
	<div class="textDiv"><span class="text">once you’re through with the stories, jump to</span></div>
	<button class="button" on:mousedown={next} on:click={onClick}
		><div class="btnText">Next Question</div>
		<img src="/assets/svg/play.svg" alt="" /></button
	>
</div>

<style>
	.container {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
	}
	.button {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		width: 200px;
		height: 53px;

		background: #ffffff;
		border-radius: 8px;
		cursor: pointer;
	}
	.textDiv {
		height: 53px;
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		margin-right: 8px;
		display: flex;
		align-items: center;
		color: rgba(255, 255, 255, 0.75);
	}
	.btnText {
		height: 20px;
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 20px;
		display: flex;
		align-items: center;
		text-align: center;
		color: #36474f;
		margin-right: 8px;
	}
	img {
		width: 20px;
		height: 20px;
	}
	@media screen and (max-width: 780px), (max-height: 520px) {
		.button {
			max-width: 150px;
			max-height: 40px;
		}
		.textDiv {
			max-height: 40px;
		}
		.btnText {
			height: 14px;
			font-size: 14px;
			line-height: 14px;
			margin-right: 6px;
		}
	}
</style>
