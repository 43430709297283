<script>
	import { onMount, onDestroy } from "svelte";
	import { Confetti } from "da-components";
	import GameTitle from "../components/GameTitle.svelte";
	import RoundInfo from "../components/RoundInfo.svelte";
	import GameOverBox from "../components/GameOverBox.svelte";
	import ControlBar from "../components/controlBar/index.svelte";

	let confettiTimeOut;

	onMount(() => {
		Confetti.startSpawning();
		confettiTimeOut = setTimeout(function () {
			Confetti.stopSpawning();
			Confetti.removeCanvas();
		}, 5000);
	});
	onDestroy(() => {
		clearTimeout(confettiTimeOut);
		Confetti?.stopSpawning();
		Confetti?.removeCanvas();
	});
</script>

<div class="container">
	<img class="bgSvg2" src="/assets/svg/thumbs.svg" alt="thumbs svg" />
	<img class="bgSvg1" src="/assets/svg/result.svg" alt="result svg" />
	<div class="gameOverContainer">
		<div class="GameTitle">
			<div class="controlbar"><ControlBar showInfoIcon={false} /></div>
			<GameTitle />
			<div class="roundInfo">
				<RoundInfo />
			</div>
		</div>
		<div class="gameOverDisplay"><GameOverBox /></div>
	</div>
</div>

<style>
	.container {
		width: 100%;
		height: 100%;
		background: #030303;
	}
	.gameOverContainer {
		width: 100%;
		height: 100%;
		font-family: Manrope;
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 3;
	}
	.bgSvg1 {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 1;
	}
	.bgSvg2 {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 2;
	}
	.GameTitle {
		width: 100%;
		height: 15%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: relative;
	}
	.controlbar {
		position: absolute;
		left: 48px;
	}
	.roundInfo {
		position: absolute;
		right: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.gameOverDisplay {
		width: 80%;
		height: 75%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
