import * as Sentry from "@sentry/browser";
import App from "./App.svelte";

if (!process.env.EMULATE) {
	console.log("sentry connected");
	Sentry.init({
		dsn: "https://64cd9da83dd24de5803438e9e09a2731@o422923.ingest.sentry.io/6091209",
		environment: process.env.APP_ENV,
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}

const app = new App({
	target: document.body,
	props: {},
});

export default app;
