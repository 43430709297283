<script>
	import { playSound } from "../services/utils.ts";
	import { ProfilePicture } from "da-components";

	export let user;
	export let pickUser = () => {};
	export let voted = false;
	export let miniRound;

	$: if (miniRound >= 0 || miniRound < 5) {
		voted = false;
	}

	let userClick = () => playSound("VOTE");
</script>

<div class="container" on:mousedown={userClick} on:click={() => pickUser(user.id)} class:picked={voted}>
	<div class="info">
		<ProfilePicture userName={user?.userName} src={user?.profilePicture} size="25" fontSize="12" />
		<div class="name">{user.userName}</div>
	</div>
	<div class="submittedCardSvg" />
</div>

<style>
	.container {
		min-width: 215px;
		min-height: 73px;
		width: 250px;
		height: 83px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 8px 8px 8px 16px;
		background: rgba(241, 241, 241, 0.16);
		box-shadow: 3px 3px 34px rgba(78, 78, 78, 0.25);
		border-radius: 8px;
		margin: 4px 0px;
		cursor: pointer;
	}
	.info {
		height: 36px;
		width: calc(100% - 35px);
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.name {
		width: calc(100% - 35px);
		height: 36px;
		font-family: manrope;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 36px;
		color: #ffffff;
		display: inline-block;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin-left: 10px;
	}
	.submittedCardSvg {
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 10px;
	}
	.container:hover {
		background: rgba(241, 241, 241, 0.35);
	}
	.picked {
		background: #ffffff;
		color: #171d20;
		pointer-events: none;
	}
	.picked .name {
		font-weight: bold;
		color: #171d20;
	}
	@media screen and (max-width: 800px), (max-height: 520px) {
		.container {
			min-width: 0;
			min-height: 0;
			max-width: 175px;
			height: 50px;
		}
		.name {
			font-size: 14px;
		}
	}
</style>
