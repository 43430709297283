import firebase from "firebase/app";
import "firebase/database";
import { getGameSessionId, getParams } from "../services/utils";

const firebaseConfig = {
	apiKey: process.env.API_KEY,
	authDomain: process.env.AUTH_DOMAIN,
	databaseURL: process.env.DATABASE_URL,
	projectId: process.env.PROJECT_ID,
	storageBucket: process.env.STORAGE_BUCKET,
	messagingSenderId: process.env.MESSAGING_SENDER_ID,
	appId: process.env.APP_ID,
};

firebase.initializeApp(firebaseConfig);
if (process.env.EMULATE) {
	const firebaseEmulators = {
		database: {
			host: "localhost",
			port: 9000,
		},
		functions: {
			host: "localhost",
			port: 5001,
		},
	};
	console.log("Automatically connecting Firebase SDKs to running emulators:");
	Object.keys(firebaseEmulators).forEach(function (key) {
		console.log(`\t${key}: http://${firebaseEmulators[key].host}:${firebaseEmulators[key].port}`);
	});
	if (firebaseEmulators.database && typeof firebase.database === "function") {
		firebase.database().useEmulator(firebaseEmulators.database.host, firebaseEmulators.database.port);
	}
	if (firebaseEmulators.firestore && typeof firebase.firestore === "function") {
		firebase.firestore().useEmulator(firebaseEmulators.firestore.host, firebaseEmulators.firestore.port);
	}
	if (firebaseEmulators.functions && typeof firebase.functions === "function") {
		firebase.functions().useEmulator(firebaseEmulators.functions.host, firebaseEmulators.functions.port);
	}
	if (firebaseEmulators.auth && typeof firebase.auth === "function") {
		firebase.auth().useEmulator(`http://${firebaseEmulators.auth.host}:${firebaseEmulators.auth.port}`);
	}
} else {
	console.log(
		"To automatically connect the Firebase SDKs to running emulators, replace '/__/firebase/init.js' with '/__/firebase/init.js?useEmulator=true' in your index.html"
	);
}

let roundValue = 1;
const userId = getParams("userId");

export function getRoundValue() {
	return roundValue;
}

// For basegame but might get modified depending on the requirements
const connectedRef = firebase.database().ref(".info/connected"); // refs to presence
export const dbRoot = firebase.database().ref("mlt"); // refs to root collection
export const dbData = firebase.database().ref("mltData"); // refs to default questions
export const dbGameSession = dbRoot.child(getGameSessionId()); // refs to a particular game session
export const dbUsers = dbGameSession.child("users"); // refs to all the users in a particular game session
export const dbHost = dbGameSession.child("host"); // refs to the host in a particular game session
export const dbUser = dbUsers.child(userId); // refs to the user itself in a particular game session

export const dbGameSessionRoundValue = dbGameSession.child("roundValue"); // refs to current round value in a particular game session
export const dbGameSessionRounds = dbGameSession.child("rounds"); // refs to all the rounds in a particular game session
export const dbGameSessionRound = () => dbGameSessionRounds.child(`${getRoundValue()}`); // returns ref to the current round in a particular game session

// Place the match data related refs here
export const dbPageKey = () => dbGameSessionRound().child("page"); // returns ref to the current screen of the current round in a particular game session
export const dbRoundQuestions = () => dbGameSessionRound().child("currRoundQuestions"); // returns ref to all questions picked by users in the current round in a particular game session
export const dbCurrQuestionKey = () => dbGameSessionRound().child("currQuestionKey"); // returns ref to the current question being played in the current round in a particular game session
export const dbRoundPickedQuestions = () => dbGameSessionRound().child("currRoundPickedQuestions"); // returns ref to all questions that have been already picked in the current round in a particular game session
export const dbAnswers = () => dbGameSessionRound().child("answers"); // returns ref to all the answers submitted by the users in the current round in a particular game session
export const dbroundTimerStarted = () => dbGameSessionRound().child("roundTimerStarted"); // returns ref which checks if a match is getting started
export const dbroundTimer = () => dbGameSessionRound().child("roundTimer"); // returns ref to timer which takes everyone to card picker.
export const dbroundcardPickerExpiryAt = () => dbGameSessionRound().child("cardPickerExpiryAt"); // returns ref to timer for card picker.
export const dbroundvotingRulesExpireyAt = () => dbGameSessionRound().child("votingRulesExpireyAt"); // returns ref to timer for voting rules.
export const dbroundvotingExpireyAt = () => dbGameSessionRound().child("votingExpireyAt"); // returns ref to timer for vote.
export const dbroundTimeTable = () => dbGameSessionRound().child("RoundTimeTable");
export const dbroundPlayerState = () => dbGameSessionRound().child("RoundPlayerState");
export const dbfinishedVoting = () => dbGameSessionRound().child("finishedVoting"); // returns ref of all who voted.
export const dbResult = () => dbGameSessionRound().child("result"); // returns ref to calculated result.
export const dbResultRound = () => dbGameSessionRound().child("resultRound"); // returns ref to the round in result screen.

//For themes
export const dbThemeId = () => dbGameSession.child("themeId");
export const dbTheme = () => dbGameSession.child("theme");
export const dbThemeData = () => dbGameSession.child("themeData");
export const dbThemeItems = () => dbGameSession.child("themeData").child("items");

export const dbShowInfo = () => dbGameSession.child("showInfo");

// Seeding DB with the user details in a particular round
export const user = {
	id: userId,
	userName: getParams("userName"),
	profilePicture: getParams("userProfilePicture"),
};
dbUser.update(user);

// Helper functions
const roundTimeValuePromise = new Promise(resolve => {
	dbGameSessionRoundValue.once("value").then(snap => {
		if (!snap.exists()) {
			roundValue = 1;
		} else {
			roundValue = snap.val();
		}
		resolve(0);
	});
});

// Event Listeners
export function listenOnFirebaseKey(firebaseKey, callback, notExitCallback) {
	Promise.resolve(roundTimeValuePromise).then(() => {
		firebaseKey.on("value", snap => {
			if (snap.exists()) {
				callback(snap.val());
			} else {
				notExitCallback && notExitCallback();
			}
		});
	});
}

dbGameSessionRoundValue.on("value", snap => {
	if (!snap.exists()) {
		dbGameSessionRoundValue.set(1);
		roundValue = 1;
		return;
	}
	roundValue = snap.val();
});

// Handles user presences
connectedRef.on("value", snap => {
	if (snap.val() === true) {
		dbUser.update({
			...user,
			online: true,
		});
		const isHost = getParams("isHost") === "true";
		if (isHost) {
			dbHost.get().then(snap => {
				if (!snap.val()) {
					dbHost.set(user.id);
				}
			});
		}
		dbUser.onDisconnect().update({
			online: firebase.database.ServerValue.TIMESTAMP,
		});
	}
});

window?.DIVE_APP?.listenOnAppThemeChange(theme => {
	console.log(theme, "listenOnAppThemeChange");
	dbTheme().set(theme);
});
