<script>
	// your script goes here
</script>

<img class="gif" src="/assets/gif/counting.gif" alt="counting" />

<style>
	.gif {
		width: 320px;
		height: 250px;
		border-radius: 8px;
	}
	@media screen and (max-width: 780px), (max-height: 520px) {
		.gif {
			width: 220px;
			height: 150px;
		}
	}
</style>
