<script>
	import { onMount } from "svelte";
	import {
		user,
		dbHost,
		dbGameSessionRounds,
		dbGameSessionRoundValue,
		listenOnFirebaseKey,
	} from "../services/database";
	import { playSound } from "../services/utils";
	import Leaving from "./image/Leaving.svelte";

	let hostId;
	let roundValue;

	let next = () => playSound("CLICK");

	let startNewRound = async () => {
		await dbGameSessionRoundValue.once("value", snap => {
			if (snap.exists()) {
				roundValue = snap.val();
			}
		});
		let newRound = roundValue + 1;
		await dbGameSessionRoundValue.set(newRound);
		await dbGameSessionRounds.child(`${newRound}`).child("page").set("LOBBY_SCREEN");
	};

	onMount(() => {
		listenOnFirebaseKey(dbHost, val => (hostId = val));
	});
</script>

{#if hostId}
	<div class="gameOverBoxContainer">
		<div class="heading"><span class="text">Woohoo that was fun ! 🎉 So many stories!</span></div>
		<div class="giphy"><Leaving /></div>
		<div class="next">
			{#if hostId == user.id}
				<button on:mousedown={next} on:click={startNewRound} class="nextRound">Start New Round ✨</button>
			{:else}
				<span class="waiting">Waiting for host to proceed....</span>
			{/if}
		</div>
	</div>
{/if}

<style>
	.gameOverBoxContainer {
		width: 100;
		height: 100;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.heading {
		width: 100;
		height: 24px;
		display: flex;
		justify-content: center;
	}
	.text {
		font-family: manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 24px;
		text-align: center;
		color: #ffffff;
	}
	.giphy {
		width: 320px;
		height: 250px;
		margin-top: 24px;
		margin-bottom: 24px;
	}
	.next {
		width: 320px;
		height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.nextRound {
		width: 100%;
		height: 100%;
		background: #ffffff;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: Manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 130%;
		color: #36474f;
		cursor: pointer;
	}
	.waiting {
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		text-align: center;
		color: #ffffff;
		opacity: 0.75;
	}
	@media screen and (max-width: 780px), (max-height: 520px) {
		.text {
			font-size: 18px;
			line-height: 18px;
		}
		.giphy {
			width: 220px;
			height: 150px;
			margin-top: 12px;
			margin-bottom: 12px;
		}
		.next {
			width: 220px;
			height: 42px;
			margin-top: 10px;
		}
		.nextRound {
			font-size: 14px;
		}
	}
</style>
