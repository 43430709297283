<script>
	import { onMount } from "svelte";
	import { user, dbHost, dbPageKey, listenOnFirebaseKey } from "../services/database";
	import { otherUsersStore } from "../services/store";
	import VotedInfoBox from "./VotedInfoBox.svelte";

	export let alreadyVotedList;

	let otherUsers;
	let onlineUsers;
	let waitingfor;
	let allUsers;
	let columnA;
	let columnB;
	let isHost;

	$: otherUsers = $otherUsersStore;

	$: {
		onlineUsers = otherUsers.filter(user => user.online);
		onlineUsers = [user, ...onlineUsers];
		allUsers = [user, ...otherUsers];
		let half = Math.ceil(allUsers.length / 2);
		columnA = allUsers.slice(0, half);
		columnB = allUsers.slice(half);
	}

	$: if (onlineUsers?.length > 0) {
		waitingfor = onlineUsers?.length;
	}

	$: {
		if (onlineUsers.length > 0) {
			if (alreadyVotedList) {
				let count = 0;
				onlineUsers.forEach(({ id }) => {
					if (!alreadyVotedList?.[id]) {
						count += 1;
					}
				});
				waitingfor = count;
			} else {
				waitingfor = onlineUsers.length;
			}
		} else if (onlineUsers.length === 0) {
			waitingfor = onlineUsers.length;
		}
	}

	$: if (waitingfor === 0 && isHost) {
		dbPageKey().set("COUNT_SCREEN");
	}

	onMount(() => {
		listenOnFirebaseKey(dbHost, val => {
			if (val === user.id) {
				isHost = true;
			} else {
				isHost = false;
			}
		});
	});
</script>

{#if onlineUsers && alreadyVotedList}
	<div class="votedBoxContainer">
		<div class="heading"><span class="text">Waiting for {waitingfor} players to complete their vote</span></div>
		<div class="votedBox">
			<div class="column columnA">
				{#each columnA as user}
					<VotedInfoBox {user} voted={alreadyVotedList[user.id] ? true : false} />
				{/each}
			</div>
			<div class="column">
				{#each columnB as user}
					<VotedInfoBox {user} voted={alreadyVotedList[user.id] ? true : false} />
				{/each}
			</div>
		</div>
	</div>
{/if}

<style>
	.votedBoxContainer {
		width: 60%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.heading {
		width: 100%;
		height: 22px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 24px;
	}
	.text {
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 18px;
		text-align: center;
		color: #ffffff;
	}
	.votedBox {
		min-width: 550px;
		height: auto;
		max-height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		overflow-y: auto;
		overflow-x: hidden;
		padding-top: 20px;
		padding-bottom: 20px;
		padding: 10px;
		border-radius: 8px;
		background: rgba(28, 12, 49, 0.25);
		margin-bottom: 8px;
	}
	.column {
		width: 50%;
		height: fit-content;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.columnA {
		margin-right: 8px;
	}
	::-webkit-scrollbar {
		display: none;
	}
	@media screen and (max-width: 780px), (max-height: 520px) {
		.votedBox {
			min-width: 0;
			width: 480px;
		}
	}
</style>
