<script>
	import GameTitle from "../components/GameTitle.svelte";
	import RoundInfo from "../components/RoundInfo.svelte";
	import Counting from "../components/image/Counting.svelte";
	import ControlBar from "../components/controlBar/index.svelte";
</script>

<div class="resultScreen">
	<div class="resultContainer">
		<div class="GameTitle">
			<div class="controlbar"><ControlBar showInfoIcon={false} /></div>
			<GameTitle />
			<div class="roundInfo">
				<RoundInfo />
			</div>
		</div>
		<div class="lobbyWaitingBody">
			<div class="textDiv"><span class="text">Get ready! the votes are being counted</span></div>
			<div class="gifDiv"><Counting /></div>
		</div>
	</div>
</div>

<style>
	.resultScreen {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.resultContainer {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.GameTitle {
		width: 100%;
		height: 15%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: relative;
	}
	.controlbar {
		position: absolute;
		left: 48px;
	}
	.roundInfo {
		position: absolute;
		right: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.lobbyWaitingBody {
		width: 100%;
		height: 85%;
		min-height: 85%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.textDiv {
		height: 20%;
		margin-bottom: 24px;
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}
	.text {
		font-family: manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 22px;
		text-align: center;
		color: #ffffff;
	}
	.gifDiv {
		width: 100%;
		height: 80%;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	@media screen and (max-width: 780px), (max-height: 520px) {
		.GameTitle {
			height: 25%;
		}
	}
</style>
