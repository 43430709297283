import { get } from "svelte/store";
import { sound } from "./store";

export const params = new URLSearchParams(window.location.search);
export const getParams = param => {
	if (params.get(param) === null || params.get(param) === "null" || params.get(param) === "") {
		return null;
	}
	return `${params.get(param)}`;
};

export function isEqual(array1, array2) {
	return JSON.stringify(array1) === JSON.stringify(array2);
}

export const getGameSessionId = () => `${getParams("roomId")}+${getParams("sessionId")}`;

export function sortUsers(users) {
	return [...users].sort((a, b) => a.id - b.id);
}

export function shuffleArray(array) {
	let currentIndex = array.length,
		randomIndex;
	while (currentIndex != 0) {
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;
		[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
	}
	return array;
}

const introMusic = new Audio("/assets/music/intro-music.mp3");
const clickSound = new Audio("/assets/music/click.wav");
const themePicked = new Audio("/assets/music/theme-picked.wav");
const threeSecCountdown = new Audio("/assets/music/three-sec-countdown_uc.wav");
const shuffle = new Audio("/assets/music/shuffle.wav");
const voteBoxClick = new Audio("/assets/music/vote-box-click.wav");
const transition = new Audio("/assets/music/transition.wav");
const reveal = new Audio("/assets/music/reveal.wav");
const applause = new Audio("/assets/music/applause.wav");

introMusic.loop = true;

export const playSound = type => {
	let value = get(sound);
	switch (type) {
		case "INTRO":
			play(introMusic, 0.04, value, false);
			break;
		case "INTROSTOP":
			stop(introMusic);
			break;
		case "CLICK":
			play(clickSound, 0.2, value);
			break;
		case "THEMEPICKED":
			play(themePicked, 0.2, value, false);
			break;
		case "THREESEC":
			play(threeSecCountdown, 0.2, value, false);
			break;
		case "THREESECSTOP":
			stop(threeSecCountdown);
			break;
		case "SHUFFLE":
			play(shuffle, 0.2, value, false);
			break;
		case "VOTE":
			play(voteBoxClick, 0.2, value);
			break;
		case "TRANSITION":
			play(transition, 0.2, value);
			break;
		case "REVEAL":
			play(reveal, 0.2, value);
			break;
		case "APPLAUSE":
			play(applause, 0.75, value);
			break;
		case "MUTE":
			introMusic.volume = 0;
			clickSound.volume = 0;
			themePicked.volume = 0;
			threeSecCountdown.volume = 0;
			shuffle.volume = 0;
			voteBoxClick.volume = 0;
			transition.volume = 0;
			reveal.volume = 0;
			applause.volume = 0;
			break;
		case "UNMUTE":
			introMusic.volume = 0.04;
			clickSound.volume = 0.2;
			themePicked.volume = 0.2;
			threeSecCountdown.volume = 0.2;
			shuffle.volume = 0.2;
			voteBoxClick.volume = 0.2;
			transition.volume = 0.2;
			reveal.volume = 0.2;
			applause.volume = 0.75;
			break;
		default:
			break;
	}
};

const play = async (sound, volume, value, refresh = true) => {
	try {
		if (refresh) {
			await sound.pause();
			sound.currentTime = 0;
		}
		sound.volume = value ? volume : 0;
		await sound.play();
	} catch (error) {
		console.log("Browser is preventing autoplay of music");
	}
};

const stop = async sound => {
	try {
		await sound.pause();
		sound.currentTime = 0;
	} catch (error) {
		console.log("Browser is preventing autoplay of music");
	}
};

export const Confetti = (() => {
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");

	canvas.style.position = "fixed";
	canvas.style["z-index"] = 9000; // more than zindex of modals(3000) and everything
	canvas.style.top = "0";
	canvas.style.left = "0";
	canvas.style.width = "100vw";
	canvas.style.height = "100vh";
	canvas.style.pointerEvents = "none";

	const onResize = () => {
		if (canvas.parentElement !== null) {
			const b = canvas.getBoundingClientRect();
			canvas.width = b.width;
			canvas.height = b.height;
		}
	};

	window.addEventListener("resize", onResize);

	const instances = [];

	const configs = {
		size: {
			min: 10,
			max: 30,
		},
		speed: {
			min: 8,
			max: 10,
		},
		colors: ["#ee5555", "#ee8355", "#eecc55", "#55eec9", "#5592ee", "#bd55ee"],
		gravity: 0.1,
		getColor() {
			return this.colors[Math.floor(Math.random() * this.colors.length)];
		},
	};

	const range = (a, b = 0) => a + Math.random() * (b - a);

	const spawn = () => {
		const h = range(configs.size.min, configs.size.max);
		const speed = range(configs.speed.min, configs.speed.max);
		instances.push({
			x: range(canvas.width),
			y: -h,
			w: range(h / 4, h / 2),
			h: h,
			vx: range(-speed / 4, speed / 4),
			vy: range(speed / 2),
			angle: range(2 * Math.PI),
			color: configs.getColor(),
		});
	};

	let isSpawning = false;

	const run = () => {
		if (isSpawning) {
			spawn();
		}

		// render
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		for (let i = instances.length - 1; i >= 0; --i) {
			const cf = instances[i];

			ctx.save();
			ctx.translate(cf.x, cf.y);
			ctx.rotate(cf.angle);
			ctx.scale(Math.cos(cf.y * 0.015), 1);
			ctx.fillStyle = cf.color;
			ctx.fillRect(-cf.w / 2, -cf.h / 2, cf.w, cf.h);
			ctx.restore();

			cf.vy += configs.gravity;

			cf.x += cf.vx;
			cf.y += cf.vy;

			cf.angle += cf.vx * 0.1;

			if (cf.y > canvas.height + cf.h) {
				instances.splice(i, 1);
			}
		}

		if (canvas.parentElement !== null) {
			window.requestAnimationFrame(run);
		}
	};

	return {
		startSpawning() {
			if (!canvas.parentElement) {
				document.body.appendChild(canvas);
				onResize();
			}
			isSpawning = true;
			run();
		},
		stopSpawning() {
			isSpawning = false;
		},
		removeCanvas() {
			if (canvas.parentElement !== null) {
				instances.length = 0;
				document.body.removeChild(canvas);
			}
		},
	};
})();

export let preLoadGifs = async page => {
	if (page === "result") {
		let video1 = document.createElement("video");
		video1.setAttribute("src", "/assets/video/story.mp4");
		let video2 = document.createElement("video");
		video2.setAttribute("src", "/assets/video/popcorn.mp4");
		let video3 = document.createElement("video");
		video3.setAttribute("src", "/assets/video/spill.mp4");
	} else if (page === "gameOver") {
		let video1 = document.createElement("video");
		video1.setAttribute("src", "/assets/video/celebration.mp4");
		let video2 = document.createElement("video");
		video2.setAttribute("src", "/assets/video/dance.mp4");
		let video3 = document.createElement("video");
		video3.setAttribute("src", "/assets/video/office.mp4");
	} else if (page === "transition") {
		let img1 = new Image();
		let img2 = new Image();
		img1.src = " /assets/gif/counting.gif";
		img2.src = " /assets/gif/game-time.gif";
	} else if (page === "voting rules") {
		let img1 = new Image();
		let img2 = new Image();
		let img3 = new Image();
		let img4 = new Image();
		img1.src = "/assets/svg/vr1.svg";
		img2.src = "/assets/svg/vr2.svg";
		img3.src = "/assets/svg/vr3.svg";
		img4.src = "/assets/svg/vr4.svg";
	} else if (page === "round rules") {
		let img1 = new Image();
		let img2 = new Image();
		let img3 = new Image();
		img1.src = "/assets/svg/lr1.svg";
		img2.src = "/assets/svg/lr2.svg";
		img3.src = "/assets/svg/lr3.svg";
	}
};
