<script>
	// your script goes here
</script>

<img class="gif" src="/assets/gif/game-time.gif" alt="game-time" />

<style>
	.gif {
		width: 360px;
		height: auto;
		margin-bottom: 8px;
		border-radius: 8px;
	}
</style>
