<script>
	import { fade, fly } from "svelte/transition";
	import SmallerTimer from "../circleTimer/smallTimer.svelte";
	export let time;
	let curr, miniTime;

	$: if (time <= 14 && time > 11) {
		curr = 0;
		miniTime = time - 11;
	} else if (time <= 11 && time > 8) {
		curr = 1;
		miniTime = time - 8;
	} else if (time <= 8 && time > 5) {
		curr = 2;
		miniTime = time - 5;
	}
</script>

<div class="container">
	{#key curr}
		<img
			class="image"
			src={`/assets/svg/lr${curr + 1}.svg`}
			alt="rule"
			in:fly={{ x: 0, duration: 1000 }}
			out:fade
		/>
	{/key}
	<div class="rounds">
		{#each Array(3) as item, i (i)}
			<div class="round">
				<div class="circle" class:curr={i <= curr} />
				{#if i === curr}
					<SmallerTimer animationTime={miniTime} />
				{/if}
			</div>
		{/each}
	</div>
</div>

<style>
	.container {
		width: 616px;
		height: 491px;
		position: relative;
	}

	.image {
		width: 100%;
		height: 100%;
		position: absolute;
	}

	.rounds {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: absolute;
		width: 86px;
		height: 24px;
		bottom: 32px;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.round {
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
	}

	.circle {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background: #ffffff;
		opacity: 0.25;
		position: absolute;
	}

	.curr {
		opacity: 1;
	}

	@media screen and (max-width: 780px), (max-height: 520px) {
		.container {
			max-width: 400px;
			max-height: 319px;
		}
		.rounds {
			bottom: 16px;
		}
	}
</style>
