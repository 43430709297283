<script>
	export let question;
	export let prevQuestion;

	let question1, question2;

	let showCardBack = false;

	const delay = ms =>
		new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve();
			}, ms);
		});

	$: if (question) {
		fliper();
	}

	let fliper = async () => {
		if (showCardBack) {
			question1 = question?.value;
			question2 = prevQuestion?.value;
		} else {
			question1 = prevQuestion?.value;
			question2 = question?.value;
		}
		await delay(200);
		showCardBack = !showCardBack;
	};
</script>

<div class="container">
	<div class="flip-box">
		<div class="flip-box-inner" class:flip-it={showCardBack}>
			<div class="cardBoxContainer1">
				<div class="card">
					<div class="container">
						<div class="cardHeader" />
						{#if question1 === undefined}
							<div class="cardContent">
								<span class="redC">WHO’S</span>
								<span class="blackC">MOST LIKELY TO...</span>
							</div>
						{:else}
							<div class="title">
								<span class="red">WHO’S</span><span class="black">MOST LIKELY TO...</span>
							</div>
							<div class="cardContent">
								<span class="question">{question1}</span>
							</div>
						{/if}
					</div>
				</div>
			</div>

			<div class="cardBoxContainer2" class:conceal-answer={showCardBack}>
				<div class="card">
					<div class="container">
						<div class="cardHeader" />
						{#if question2 === undefined}
							<div class="cardContent">
								<span class="redC">WHO’S</span>
								<span class="blackC">MOST LIKELY TO...</span>
							</div>
						{:else}
							<div class="title">
								<span class="red">WHO’S</span><span class="black">MOST LIKELY TO...</span>
							</div>
							<div class="cardContent">
								<span class="question">{question2}</span>
							</div>
						{/if}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<style>
	.container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.flip-box {
		background-color: transparent;
		min-width: 222px;
		min-height: 329px;
		width: 250px;
		height: 370px;
	}

	/* This container is needed to position the front and back side */
	.flip-box-inner {
		position: relative;
		min-width: 222px;
		min-height: 329px;
		width: 250px;
		height: 370px;
		text-align: center;
		transition: transform 0.6s;
		transform-style: preserve-3d;
	}

	/* Do an horizontal flip on button click */
	.flip-it {
		transform: rotateY(180deg);
	}
	.cardBoxContainer1,
	.cardBoxContainer2 {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		-webkit-backface-visibility: hidden; /* Safari */
		backface-visibility: hidden;
	}
	.cardBoxContainer2 {
		transform: rotateY(180deg);
		z-index: 15;
	}
	@keyframes revealTextSlowly {
		to {
			color: white;
		}
	}

	.conceal-answer {
		animation: revealTextSlowly 0.3s forwards;
	}
	.card {
		min-width: 222px;
		min-height: 329px;
		width: 250px;
		height: 370px;
		border-radius: 10px;
		background: #ffffff;
	}
	.container {
		width: 100%;
		height: 100%;
		position: relative;
	}
	.cardHeader {
		width: 100%;
		height: 28px;
		max-height: 28px;
		position: absolute;
		background: #d23a47;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		z-index: 10;
	}
	.title {
		width: 100%;
		height: 38px;
		margin-top: 47px;
		position: absolute;
		z-index: 11;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.red {
		width: 100%;
		height: 14px;
		font-family: MODULAR-B;
		font-style: normal;
		font-weight: normal;
		font-size: 13.9831px;
		line-height: 13px;
		color: #d23a47;
		padding-left: 10px;
		text-align: left;
	}
	.black {
		width: 100%;
		height: 23px;
		font-family: MODULAR-B;
		font-style: normal;
		font-weight: normal;
		font-size: 23.3212px;
		line-height: 19px;
		color: #000000;
		padding-left: 10px;
		text-align: left;
	}
	.cardContent {
		width: 100%;
		height: 100%;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.question {
		width: 100%;
		height: fit-content;
		font-family: manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 23.5921px;
		line-height: 118%;
		padding: 0 10px 0 10px;
		color: #121212;
		text-align: left;
		overflow-wrap: break-word;
		transform: translate(0, 20%);
	}
	.redC {
		width: 100%;
		height: 25px;
		font-family: MODULAR-B;
		font-style: normal;
		font-weight: normal;
		font-size: 26.9489px;
		line-height: 25px;
		color: #cf3946;
		padding-left: 18px;
		text-align: left;
	}
	.blackC {
		width: 100%;
		height: auto;
		font-family: MODULAR-B;
		font-style: normal;
		font-weight: normal;
		font-size: 54.9344px;
		line-height: 50px;
		padding-left: 18px;
		color: #000000;
		text-align: left;
	}
	/* changed */
	@media screen and (max-width: 800px), (max-height: 520px) {
		.flip-box {
			min-width: 0;
			min-height: 0;
			width: 133px;
			height: 197px;
			max-width: 222px;
			max-height: 329px;
		}
		.flip-box-inner {
			min-width: 0;
			min-height: 0;
			width: 133px;
			height: 197px;
			max-width: 222px;
			max-height: 329px;
		}
		.card {
			min-width: 0;
			min-height: 0;
			width: 133px;
			height: 197px;
			max-width: 222px;
			max-height: 329px;
		}
		.cardHeader {
			height: 17px;
			max-height: 17px;
		}
		.title {
			height: 21px;
			margin-top: 31px;
		}
		.red {
			height: 12px;
			font-size: 12px;
			line-height: 12px;
		}
		.black {
			height: 16px;
			font-size: 16px;
			line-height: 16px;
		}
		.question {
			font-size: 16px;
		}
		.redC {
			height: 18px;
			font-size: 20px;
			line-height: 18px;
			padding-left: 12px;
		}
		.blackC {
			font-size: 40px;
			line-height: 40px;
			padding-left: 12px;
		}
	}
</style>
