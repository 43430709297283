<script>
	import { onMount, onDestroy } from "svelte";
	import {
		user,
		dbRoundPickedQuestions,
		dbroundvotingExpireyAt,
		dbAnswers,
		dbfinishedVoting,
		dbroundPlayerState,
		listenOnFirebaseKey,
	} from "../services/database";
	import { getParams, preLoadGifs } from "../services/utils";
	import GameTitle from "../components/GameTitle.svelte";
	import RoundInfo from "../components/RoundInfo.svelte";
	import LobbyWaitingBody from "../components/LobbyWaitingBody.svelte";
	import VotedBox from "../components/VotedBox.svelte";
	import ControlBar from "../components/controlBar/index.svelte";

	let questions;
	let miniRound = -1;
	let question;
	let prevQuestion;
	let expiryAt;
	let time;
	let expiryTimer;
	let userOnWhichRound = 0;
	let alreadyVotedList;
	let alreadyVoted = false;
	let playerState;
	let timeBeforeNextRound;
	let expiryTimer2;

	$: if (questions?.length > 0) {
		if (time <= 105 && time > 85) {
			miniRound = 0;
		} else if (time <= 85 && time > 65) {
			miniRound = 1;
		} else if (time <= 65 && time > 45) {
			miniRound = 2;
		} else if (time <= 45 && time > 25) {
			miniRound = 3;
		} else if (time <= 25 && time > 5) {
			miniRound = 4;
		} else if (time <= 5) {
			alreadyVoted = true;
		}
	}

	$: if (miniRound === 0 && userOnWhichRound === 0) {
		userOnWhichRound = miniRound;
		dbroundPlayerState()
			.child(`${user.id}`)
			.set({
				position: userOnWhichRound,
				willFinishAt: new Date(new Date().getTime() + 20 * 1000).getTime(),
			});
	}

	$: if (miniRound > 0 && miniRound > userOnWhichRound) {
		userOnWhichRound = miniRound;
		dbroundPlayerState()
			.child(`${user.id}`)
			.set({
				position: miniRound,
				willFinishAt: new Date(new Date().getTime() + 20 * 1000).getTime(),
			});
	}

	$: if (userOnWhichRound >= 0 && questions) {
		question = questions[userOnWhichRound];
		prevQuestion = userOnWhichRound >= 1 ? questions[userOnWhichRound - 1] : undefined;
	}

	$: if (playerState && playerState.position) {
		userOnWhichRound = playerState.position;
	}

	$: if (playerState && playerState.willFinishAt) {
		clearInterval(expiryTimer2);
		expiryTimer2 = setInterval(() => {
			timeBeforeNextRound = Math.round((playerState.willFinishAt - new Date().getTime()) / 1000);
		}, 1000);
	}

	$: if (timeBeforeNextRound <= 0 && userOnWhichRound <= 4) {
		userOnWhichRound = userOnWhichRound + 1;
		dbroundPlayerState()
			.child(`${user.id}`)
			.set({
				position: userOnWhichRound,
				willFinishAt: new Date(new Date().getTime() + 20 * 1000).getTime(),
			});
	} else if (userOnWhichRound == 5) {
		dbfinishedVoting().child(`${user.id}`).set(true);
	}

	$: if (alreadyVotedList && alreadyVotedList[user.id]) {
		alreadyVoted = true;
	}

	let voteChosen = async id => {
		await dbAnswers().child(userOnWhichRound).child(`${user.id}`).set(id);
	};

	let goNext = async id => {
		await dbAnswers().child(userOnWhichRound).child(`${user.id}`).set(id);
		if (userOnWhichRound >= 0 && userOnWhichRound < 4) {
			userOnWhichRound = userOnWhichRound + 1;
			await dbroundPlayerState()
				.child(`${user.id}`)
				.set({
					position: userOnWhichRound,
					willFinishAt: new Date(new Date().getTime() + 20 * 1000).getTime(),
				});
		} else if (userOnWhichRound == 4) {
			await dbfinishedVoting().child(`${user.id}`).set(true);
		}
	};

	onMount(async () => {
		let userId = getParams("userId");
		listenOnFirebaseKey(dbRoundPickedQuestions(), val => (questions = val));
		listenOnFirebaseKey(dbfinishedVoting(), val => (alreadyVotedList = val));
		listenOnFirebaseKey(dbroundPlayerState(), val => (playerState = val[userId]));
		preLoadGifs("transition");
		preLoadGifs("result");
		await dbroundvotingExpireyAt()
			.once("value")
			.then(val => {
				expiryAt = val.val();
			});
		expiryTimer = setInterval(() => {
			time = Math.round((expiryAt - new Date().getTime()) / 1000);
		}, 1000);
	});
	onDestroy(() => {
		clearInterval(expiryTimer);
	});
</script>

<div class="votingScreen">
	<div class="votingContainer">
		<div class="GameTitle">
			<div class="controlbar"><ControlBar showInfoIcon={false} /></div>
			<GameTitle />
			<div class="roundInfo">
				<RoundInfo />
			</div>
		</div>
		<div class="lobbyWaitingBody">
			{#if question && time <= 105 && !alreadyVoted}
				<LobbyWaitingBody {question} {prevQuestion} miniRound={userOnWhichRound} {goNext} {voteChosen} />
			{:else if (question && time > 105) || alreadyVoted}
				<VotedBox {alreadyVotedList} />
			{/if}
		</div>
	</div>
</div>

<style>
	.votingScreen {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.votingContainer {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.GameTitle {
		width: 100%;
		height: 15%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: relative;
	}
	.controlbar {
		position: absolute;
		left: 48px;
	}
	.roundInfo {
		position: absolute;
		right: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.lobbyWaitingBody {
		width: 100%;
		height: 85%;
		min-height: 85%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	@media screen and (max-width: 780px) {
		.GameTitle {
			height: 25%;
		}
	}
</style>
