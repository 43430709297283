<script>
	import { ProfilePicture } from "da-components";

	export let user;
	export let voted = false;
</script>

{#if user}
	<div class="container">
		<div class="info">
			<ProfilePicture userName={user?.userName} src={user?.profilePicture} size="25" fontSize="12" />
			<div class="name">{user.userName}</div>
		</div>
		<div class="submittedCardSvg">
			{#if voted}
				<img src="/assets/svg/submittedCard.svg" alt="card" />
			{:else if !user.online}
				<img src="/assets/svg/disconnected.svg" alt="disconnected" />
			{/if}
		</div>
	</div>
{/if}

<style>
	.container {
		width: 100%;
		height: 85px;
		min-width: 191px;
		min-height: 75.6px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 8px 8px 8px 16px;
		background: rgba(241, 241, 241, 0.25);
		box-shadow: 3px 3px 34px rgba(78, 78, 78, 0.25);
		border-radius: 8px;
		margin: 4px 0px;
	}
	.info {
		height: 25px;
		width: calc(100% - 35px);
		display: flex;
		justify-content: flex-start;
	}
	.name {
		width: calc(100% - 35px);
		height: 25px;
		font-family: manrope;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 25px;
		color: #ffffff;
		display: inline-block;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin-left: 10px;
	}
	.submittedCardSvg {
		width: 25px;
		height: 25px;
		margin-left: 10px;
	}
	@media screen and (max-width: 780px), (max-height: 520px) {
		.container {
			min-width: 0;
			min-height: 0;
			max-width: 175px;
			min-height: 50px;
			max-height: 50px;
		}
		.name {
			font-size: 14px;
		}
	}
</style>
