<script>
	import { onMount, afterUpdate, onDestroy } from "svelte";
	import Bar from "./Bar.svelte";
	import Avatar from "../VotedOnAvatar.svelte";
	import Tooltip from "../tooltip/Index.svelte";
	export let resultOfThisRound;
	export let allUsers;

	let dataOfVoted = [];
	let width;
	let baseSize;
	let maxVote;
	let w;
	let doit;
	let isWinner = false;

	$: if (width && width > 1000) {
		baseSize = 80;
	} else {
		baseSize = 50;
	}

	let creatingGraph = async () => {
		await generateData();
		await generateLineSize();
	};
	let generateData = async () => {
		let dataArray = [];
		let maxVoteInRound = 0;
		for (const [key, value] of Object.entries(resultOfThisRound)) {
			let votedUser = allUsers.filter(user => user?.id == key);
			let votedUserName = votedUser[0]?.userName;
			let votedUserProfilePicture = votedUser[0]?.profilePicture;
			let votes = value.count;
			maxVoteInRound = votes > maxVoteInRound ? votes : maxVoteInRound;
			let userId = key;
			let voters = [];
			let votedBy = value.votedBy;
			if (votedBy.length >= 0) {
				votedBy.forEach(voter => {
					let personWhoVoted = allUsers.filter(user => user.id == voter);
					let userName = personWhoVoted[0]?.userName;
					let profilePicture = personWhoVoted[0]?.profilePicture;
					voters = [...voters, { userName, profilePicture }];
				});
			}
			dataArray = [...dataArray, { userId, votedUserName, votedUserProfilePicture, votes, voters }];
		}
		dataArray.sort((a, b) => b.votes - a.votes);
		if (dataArray[0]?.votes > dataArray[1]?.votes) {
			isWinner = true;
		}
		maxVote = maxVoteInRound;
		dataOfVoted = [...dataArray];
	};

	let generateLineSize = async () => {
		let sizePerVote = w / maxVote;
		if (sizePerVote >= baseSize) {
			let data = dataOfVoted.map(voted => {
				let barSize;
				if (voted.votes === 1) {
					barSize = sizePerVote - 20;
				} else {
					barSize = sizePerVote * voted.votes - 30;
				}
				return { ...voted, barSize };
			});
			dataOfVoted = data;
		} else {
			let data = dataOfVoted.map(voted => {
				let barSize;
				if (voted.votes === 1) {
					barSize = baseSize;
				} else {
					barSize = sizePerVote * (voted.votes - 1);
				}
				return { ...voted, barSize };
			});
			dataOfVoted = data;
		}
	};

	$: if (resultOfThisRound && allUsers) {
		creatingGraph();
	}

	let windowResizeChecker = () => {
		clearTimeout(doit);
		doit = setTimeout(function () {
			creatingGraph();
		}, 10);
	};

	afterUpdate(async () => {
		window?.addEventListener("resize", windowResizeChecker);
	});
	onMount(async () => {
		window?.addEventListener("resize", windowResizeChecker);
	});
	onDestroy(async () => {
		window?.removeEventListener("resize", windowResizeChecker);
	});
</script>

<svelte:window bind:innerWidth={width} />

<div class="container">
	<div class="header">
		<span class="head1">🔥 What do you <span class="redText">think?</span></span>
	</div>
	{#if dataOfVoted.length > 0}
		<div class="content">
			{#each dataOfVoted as user, index}
				<div class="chart" class:winner={index === 0 && isWinner}>
					<div class="image" class:winner={index === 0 && isWinner}>
						<Avatar
							userName={user.votedUserName}
							winner={index === 0 && isWinner}
							src={user.votedUserProfilePicture}
						/>
					</div>
					<div class="graph" class:winner={index === 0 && isWinner} bind:clientWidth={w}>
						<Bar
							{user}
							winner={index === 0 && isWinner}
							pos={dataOfVoted.length - 1 > index || index == 0 ? "bottom" : "top"}
						/>
						<Tooltip voters={user.voters} winner={index === 0 && isWinner}>
							<div class="points" class:winner={index === 0 && isWinner}>
								<span class="count">{user.votes} </span>
							</div>
						</Tooltip>
					</div>
				</div>
			{/each}
		</div>
	{/if}
</div>

<style>
	.container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.header {
		width: 100%;
		height: 13%;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	.head1 {
		font-family: MODULAR-B;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		line-height: 24px;
		color: #36474f;
		margin-bottom: 4px;
	}
	.redText {
		color: #d23a47;
	}
	.content {
		width: 100%;
		height: 87%;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 16px;
		background: #f1f1f1;
		border-radius: 8px;
	}
	.chart {
		width: 100%;
		height: 40px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 8px;
	}
	.image {
		width: 40px;
		height: 40px;
		margin-right: 14px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.graph {
		width: calc(100% - 40px);
		height: 40px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.graph div {
		position: relative;
	}

	.points {
		width: 32px;
		height: 32px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		background: #e6a600;
		border: 1.10843px solid #eaeaea;
		box-sizing: border-box;
		border-radius: 50%;
		margin-left: -14px;
		cursor: pointer;
	}
	.count {
		font-family: Manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 14px;

		display: flex;
		align-items: center;
		letter-spacing: 1.3px;
		text-transform: uppercase;
		color: #000000;
	}
	.winner {
		height: 40px;
	}
	.winner .points {
		width: 40px;
	}
	::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-width: 780px), (max-height: 520px) {
		.content {
			padding: 14px;
		}
		.head1 {
			font-size: 14px;
			line-height: 14px;
		}
		.chart {
			height: 24px;
			margin-bottom: 8px;
		}
		.image {
			width: 30px;
			height: 30px;
			margin-right: 8px;
		}
		.graph {
			height: 24px;
		}
		.points {
			width: 24px;
			height: 24px;
		}
		.winner {
			height: 30px;
		}
		.winner .points {
			width: 30px;
		}
	}
</style>
