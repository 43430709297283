<script>
	import { onDestroy } from "svelte";
	import { notification } from "../../services/store";
	import List from "./List.svelte";

	export let themes = {
		disconnected: "/assets/svg/disconnected.svg",
		reconnected: "/assets/svg/reconnected.svg",
		tempDisconnected: "/assets/svg/tempDisconnect.svg",
		hostLeft: "/assets/svg/hostLeft.svg",
	};
	export let timeout = 5000;
	let count = 0;
	let toasts = [];
	let unsubscribe;

	function animateOut(node, { delay = 0, duration = 1000 }) {
		function vhTOpx(value) {
			var w = window,
				d = document,
				e = d.documentElement,
				g = d.getElementsByTagName("body")[0],
				x = w.innerWidth || e.clientWidth || g.clientWidth,
				y = w.innerHeight || e.clientHeight || g.clientHeight;
			return (y * value) / 100;
		}

		return {
			delay,
			duration,
			css: t => `opacity: ${(t - 0.7) * 1}; transform-origin: top right;`,
		};
	}

	function createToast(msg, theme) {
		const icon = themes[theme];
		toasts = [
			{
				id: count,
				msg,
				icon,
				timeout,
				width: "100%",
			},
			...toasts,
		];
		count = count + 1;
	}

	unsubscribe = notification.subscribe(value => {
		if (!value) {
			return;
		}
		createToast(value.message, value.type);
		notification.set();
	});

	onDestroy(unsubscribe);

	function removeToast(id) {
		toasts = toasts.filter(t => t.id != id);
	}
</script>

<ul class="toasts">
	{#each toasts as toast (toast.id)}
		<List {toast} {animateOut} {removeToast} />
	{/each}
</ul>

<style>
	:global(.toasts) {
		list-style: none;
		position: fixed;
		bottom: 0;
		right: 0;
		padding: 0;
		margin: 0;
		z-index: 9999;
	}
</style>
