<script>
	// your script goes here
</script>

<svg width="100%" height="100%" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_5930_56260)">
		<path
			d="M24.8926 48C35.4092 48 41.2556 48 45.0741 44.1815C48.8926 40.363 48.8926 34.5167 48.8926 24C48.8926 13.4833 48.8926 7.63697 45.0741 3.81848C41.2556 -7.04252e-07 35.4092 0 24.8926 0C14.3759 0 8.52955 -7.04252e-07 4.71106 3.81848C0.892577 7.63697 0.892578 13.4833 0.892578 24C0.892578 34.5167 0.892577 40.363 4.71106 44.1815C8.52955 48 14.3759 48 24.8926 48Z"
			fill="#36474F"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M48.8929 24.3692H0.893555C0.900727 13.9665 1.06251 8.04212 4.7117 4.11221C8.5302 0 14.3766 0 24.8932 0C35.4099 0 41.2563 0 45.0747 4.11221C48.7239 8.04212 48.8857 13.9665 48.8929 24.3692Z"
			fill="#D23A47"
		/>
		<circle cx="25.1233" cy="24.559" r="16.9846" fill="white" />
		<path
			d="M26.4949 14.7956C27.4121 16.3202 26.3726 18.228 25.5981 19.8913C24.9295 21.3221 25.0315 22.9446 26.8211 22.7367C28.9775 22.488 29.8499 22.3168 31.6843 22.1904C33.5392 22.0641 34.1221 22.8223 34.1221 23.5275C34.1221 24.6649 33.743 24.7505 33.743 25.1867C33.743 25.6229 35.1657 25.3416 35.1657 26.7724C35.1657 28.2033 34.0528 28.1584 34.0528 28.5579C34.0528 28.9574 35.1372 29.1368 35.1372 30.2823C35.1372 31.4278 33.6778 31.5787 33.6778 32.0515C33.6778 32.5244 34.1506 32.6467 34.1506 33.356C34.1466 34.0653 32.6709 35.6226 27.4529 35.6226C18.562 35.6226 19.6138 33.4946 15.4639 33.4946V25.6229C17.6244 25.6229 17.5062 23.711 18.9982 22.2516C20.5024 20.7799 23.3805 17.4454 23.9471 14.816C24.3221 13.0549 25.9324 13.8539 26.4949 14.7956Z"
			fill="#36474F"
		/>
	</g>
	<defs>
		<clipPath id="clip0_5930_56260">
			<rect width="48" height="48" fill="white" transform="translate(0.892578)" />
		</clipPath>
	</defs>
</svg>

<style>
	/* your styles go here */
</style>
