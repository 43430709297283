<script>
	import { onMount } from "svelte";
	import { getParams } from "../services/utils";
	import CircleTimer from "../components/circleTimer/index.svelte";
	import { dbroundPlayerState, listenOnFirebaseKey } from "../services/database";

	export let miniRound = 0;
	let animationTime;

	onMount(() => {
		let userId = getParams("userId");
		listenOnFirebaseKey(dbroundPlayerState(), val => {
			let playerState = val[userId];
			let willFinishAt = playerState.willFinishAt;
			animationTime = Math.round((willFinishAt - new Date().getTime()) / 1000);
		});
	});
</script>

{#if animationTime <= 20 && animationTime >= 0}
	<div class="votingRoundContainer">
		<div class="rounds">
			{#each Array(5) as item, i}
				<div class="item">
					{#if miniRound === i}
						<div class="timer"><CircleTimer {animationTime} /></div>
						<div class="circle selected"><span class="count">{i + 1}</span></div>
					{:else if miniRound > i}
						<div class="timer past"><CircleTimer animationTime="0" /></div>
						<div class="circle"><span class="count">{i + 1}</span></div>
					{:else}
						<div class="circle"><span class="count">{i + 1}</span></div>
					{/if}
				</div>
			{/each}
		</div>
	</div>
{/if}

<style>
	.votingRoundContainer {
		width: 100%;
		height: 100%;
		margin-top: 24px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
	.rounds {
		width: 222px;
		height: 48px;
		max-width: 277px;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}

	.item {
		height: 100%;
		position: relative;
		margin-left: 5px;
		margin-right: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.timer {
		width: 48px;
		height: 48px;
		position: absolute;
	}
	.past {
		width: 30px;
		height: 30px;
	}
	.circle {
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background: #6c44a8;
		opacity: 0.5;
		position: absolute;
	}
	.count {
		font-family: Manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 16px;
		color: #ffffff;
	}
	.selected {
		width: 30px;
		height: 30px;
		opacity: 1;
	}
	@media screen and (max-width: 780px), (max-height: 520px) {
		.votingRoundContainer {
			margin-top: 14px;
		}
		.rounds {
			width: 133px;
			max-width: 222px;
			justify-content: space-between;
		}
		.timer {
			width: 30px;
			height: 30px;
			position: absolute;
		}
		.circle {
			width: 18px;
			height: 18px;
		}
		.count {
			font-family: Manrope;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 14px;
			color: #ffffff;
		}
		.selected {
			width: 20px;
			height: 20px;
		}
	}
</style>
