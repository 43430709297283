<script>
	import { onDestroy, onMount } from "svelte";
	export let expiryAt;

	let expiryTimer, time;

	onMount(() => {
		time = Math.round((expiryAt - new Date().getTime()) / 1000);
		expiryTimer = setInterval(() => {
			time = Math.round((expiryAt - new Date().getTime()) / 1000);
		}, 1000);
	});
	onDestroy(() => {
		clearInterval(expiryTimer);
	});
</script>

{#if time > 0 && time <= 30}
	<div class="circle">
		<div class="text">{time}</div>
	</div>
{/if}

<style>
	.circle {
		width: 100%;
		height: 100%;
		border: 10px solid #ffffff;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.text {
		font-family: Manrope;
		font-style: normal;
		font-weight: 800;
		font-size: 110px;
		line-height: 110px;
		color: #ffffff;
	}
	@media screen and (max-width: 780px), (max-height: 520px) {
		.text {
			font-size: 50px;
			line-height: 50px;
		}
	}
</style>
