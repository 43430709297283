<script>
	export let toast;
	export let animateOut;
	export let removeToast;

	setTimeout(function () {
		removeToast(toast.id);
	}, 5000);
</script>

<li class="toast" id={toast.id} out:animateOut>
	<img src={toast.icon} alt="notification icon" />
	<div class="content">
		{toast.msg}
	</div>
</li>

<style>
	.toast {
		position: relative;
		margin: 1vh 1vw;
		padding: 5px;
		max-width: 40vw;
		position: relative;
		animation: animate-in 600ms forwards;
		background-color: #5e576a;
		backdrop-filter: blur(4px);
		border-radius: 8px;
		display: flex;
		align-items: center;
	}

	img {
		width: 35px;
		height: 35px;
		margin-left: 1vw;
	}

	.toast > .content {
		padding: 1vw;
		display: block;
		font-family: Manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 16px;

		color: #ffffff;
	}

	.toast:before,
	.toast:after {
		content: "";
		position: absolute;
		z-index: -1;
		top: 50%;
		bottom: 0;
		left: 1vw;
		right: 1vw;
		border-radius: 100px / 10px;
	}

	.toast:after {
		right: 1vw;
		left: auto;
		transform: skew(8deg) rotate(3deg);
	}
	@keyframes animate-in {
		0%,
		60%,
		75%,
		90%,
		to {
			-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
			animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		}
		0% {
			opacity: 0;
			transform: translate3d(3000px, 0, 0);
		}
		60% {
			opacity: 1;
			transform: translate3d(-25px, 0, 0);
		}
		75% {
			transform: translate3d(10px, 0, 0);
		}
		90% {
			transform: translate3d(-5px, 0, 0);
		}
		to {
			transform: none;
		}
	}
	@media (min-width: 480px) {
		@keyframes animate-in {
			0%,
			60%,
			75%,
			90%,
			to {
				-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
				animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
			}
			0% {
				opacity: 0;
				transform: translate3d(3000px, 0, 0);
			}
			60% {
				opacity: 1;
				transform: translate3d(-25px, 0, 0);
			}
			75% {
				transform: translate3d(10px, 0, 0);
			}
			90% {
				transform: translate3d(-5px, 0, 0);
			}
			to {
				transform: none;
			}
		}
	}
</style>
