<script>
	import TooltipAvatar from "./TooltipAvatar.svelte";

	export let voters;
	let isHovered = false;
	let x;
	let y;
	let w, h;

	let distance;

	$: if (w <= 780 || h <= 520) {
		distance = 72;
	} else {
		distance = 100;
	}

	function mouseOver(event) {
		isHovered = true;
		x = event.pageX - distance;
		y = event.pageY + 10;
	}
	function mouseLeave() {
		isHovered = false;
	}
</script>

<svelte:window bind:innerWidth={w} bind:innerHeight={h} />

<div on:mouseleave={mouseLeave} on:mouseover={mouseOver} on:focus>
	<slot />
</div>

{#if isHovered}
	<div style="top: {y}px; left: {x}px;" class="tooltip">
		{#each voters as voter}
			<div class="container">
				<TooltipAvatar userName={voter.userName} src={voter.profilePicture} />
				<div class="name">
					<span class="text">{voter.userName}</span>
				</div>
			</div>
		{/each}
	</div>
{/if}

<style>
	.tooltip {
		width: 191px;
		max-height: 189px;
		background: #ffffff;
		box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);
		border-radius: 8px;
		position: fixed;
		z-index: 15;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 16px 16px 8px 16px;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.container {
		width: 100%;
		height: 25px;
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;
	}
	.name {
		width: calc(100% - 35px);
		height: 25px;
		margin-left: 10px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.text {
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 16px;
		color: #36474f;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	@media screen and (max-width: 780px), (max-height: 520px) {
		.tooltip {
			width: 120px;
			max-height: 110px;
			padding: 8px 8px 4px 8px;
		}
		.container {
			height: 16px;
			margin-bottom: 4px;
		}
		.name {
			width: calc(100% - 22px);
			height: 16px;
			margin-left: 6px;
		}
		.text {
			font-size: 12px;
			line-height: 14px;
		}
	}
</style>
