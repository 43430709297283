<script>
	let items = ["story.mp4", "popcorn.mp4", "spill.mp4"];
	const item = Math.floor(Math.random() * items.length);
</script>

<video class="gif" autoplay loop muted playsinline>
	<source src={`/assets/video/${items[item]}`} type="video/mp4" />
</video>

<style>
	.gif {
		width: 100% !important;
		height: 100% !important;
		object-fit: cover;
		border-radius: 8px;
	}
</style>
