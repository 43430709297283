<script>
	export let animationTime = 3;

	let animationTimeCSS = `${animationTime}s`;
	let strokePos = 0 + 200 * animationTime;
</script>

<svg viewbox="0 0 200 200" style="--var-pos: {strokePos};">
	<circle class="circle" cx="50%" cy="50%" r="45%" style="--var-time: {animationTimeCSS}" />
</svg>

<style>
	@keyframes circletimer {
		0% {
			stroke-dashoffset: var(--var-pos);
			stroke-dasharray: 600;
		}
		100% {
			stroke-dashoffset: 0;
			stroke-dasharray: 600;
		}
	}

	svg {
		background-color: transparent;
		position: absolute;
		background-color: transparent;
		top: 50%;
		left: 50%;
		width: 24px;
		height: 24px;
		transform: translate(-50%, -50%) rotateZ(-90deg);
		position: absolute;
	}
	.circle {
		stroke: #ffffff;
		stroke-width: 10;
		fill: transparent;
		stroke-dashoffset: 600;
		stroke-dasharray: 0;
		animation-duration: var(--var-time);
		animation-name: circletimer;
		animation-timing-function: linear;
	}
</style>
