<script>
	import { sound } from "../../services/store";
	import { playSound } from "../../services/utils";
	import Mute from "../svg/Mute.svelte";
	import Unmute from "../svg/Unmute.svelte";
	import Info from "../svg/Info.svelte";

	export let showInfoIcon = false;

	let soundState;

	$: soundState = $sound;

	$: if (soundState === false) {
		playSound("MUTE");
	} else {
		playSound("UNMUTE");
	}

	let onClickSound = () => {
		sound.update(value => !value);
	};
</script>

<div class="container">
	<div on:click={onClickSound} class="soundControler">
		{#if soundState}
			<Unmute />
		{:else}
			<Mute />
		{/if}
	</div>
	<div class="infoControler">
		{#if showInfoIcon}
			<Info />
		{/if}
	</div>
</div>

<style>
	.container {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.soundControler {
		height: 34px;
		margin-right: 8px;
	}
	.infoControler {
		height: 34px;
	}
</style>
